import SectionText from "../../components/sectionText";
import { Imagen } from "../../interfaces/imagen";
import "./style.css";

export default function HockeySectionText(){

    const historia: Imagen = {
        id: 1,
        titulo: ``,
        subtitulo: '',
        descripcion: `El hockey del Club San Luis inició sus actividades en el año 2007 en el Colegio San Luis, siendo inscripto en la Asociación de Hockey de Buenos Aires (AHBA) en el año 2009 en la división pasto natural B. Por ese entonces se entrenaba y jugaba en el campo de deportes.

En el año 2015 inauguramos la cancha de hockey sintético teniendo dos tiras completas, por ese entonces la tira A lo hacía en la división F de sintético y la B en la F de pasto natural.
En el año 2023 la tira A ascendió a la B y la tira B a la D, mientras que la tira C empezó a competir en la división G.
`,
        imagenUrl: "",
        tipoImagen: {
            id: 3,
            descripcion: 'Seccion'
        }
    }

    return (
        <section id="nuestra-escencia" className="container-fluid pt-5 px-1 px-md-3 h-100">
            <h3 className="text-start px-3 px-md-5 fw-bold">Nuestra escencia</h3>
            <SectionText info={historia}></SectionText>
        </section>
    )
}