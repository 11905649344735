import { enviroment } from "../interfaces/enviroment";
import { Documento } from "../interfaces/documento";
import { UnauthorizedLogout } from "../utils/unauthorizedLogout";
import { MockDocumentos } from "../mocks/documentos";

const BASE_URL: string = enviroment.urlBase() + "api/Documento";
const ENV_DEMO: boolean = enviroment.demo();
let documentos = localStorage.getItem("documentos") ? JSON.parse(localStorage.getItem("documentos")!) : undefined;

export const GetAllDocumentos = async (): Promise<Documento[]> => {
    if (ENV_DEMO){
        if (documentos === undefined){
            documentos = MockDocumentos;
            localStorage.setItem("documentos", JSON.stringify(documentos));
        }
        
        return documentos;
    }
    const url = `${BASE_URL}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: Documento[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const GetAllDocumentosByType = async (seccionId?: number): Promise<Documento[]> => {
    if (ENV_DEMO){
        return MockDocumentos;
    }
    const url = `${BASE_URL}/${seccionId}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: Documento[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const InsertDocumento= async (data: any, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        localStorage.setItem("documentos", JSON.stringify([...documentos, data]));
        return 0;
    }
    const url = `${BASE_URL}`;
    //const JwtToken = localStorage.getItem("token")?.replace(/['"]+/g, '');
    let result;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        result = await response.json();
        if(response.ok && response.status == 201){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const UpdateDocumento = async (data: any, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    const url = `${BASE_URL}`; 
    let result;
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);
        }
        result = await response.json();
        if(response.ok && response.status == 200){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const DeleteDocumento = async (id: number, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        documentos = documentos.filter((x: any) => x.id != id);
        localStorage.setItem("documentos", JSON.stringify(documentos));   
        return "Documento eliminado exitosamente";
    }
    const url = `${BASE_URL}/${id}`;    
    let result;
    try {
        const response = await fetch(url, {
            method: "DELETE",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(id) 
        })        
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }   
        
        if(response.ok){
            return response;
        }     
    } catch (error) {
        return undefined;
    }    
}