import SectionTextColumns from "../../components/sectionTextColumns"
import { Imagen } from "../../interfaces/imagen"
import "./style.css"

export default function ClubSocios() {
    return (
        <section id="club-socios" className="container-fluid py-3 h-100">
            <h3 className="text-center px-3 px-md-5 fw-bold py-4">Socios</h3>
            <article className="d-flex flex-column justify-content-between px-3 px-md-5 mb-3">
                <h6 className="text-start fw-bold text-secondary py-4">ADMISIÓN</h6>
                <div className="categorias__column mb-2">
                    <p className="mb-1">Para asociarse al Club se deberá completar una ficha de solicitud de ingreso, la misma estará a disposición en la Secretaria Administrativa.</p>
                    <br />
                    <p className="mb-1">La Comisión Directiva se reserva en todos los casos, el derecho de admisión de asociados cualquiera sea la categoría.</p>
                    <p className="mb-1">Se fija una cuota de ingreso para la categoría d sociuo activo, tanto de Rugby como de Hockey.</p>
                    <br />
                    <p className="mb-1">El ingreso a las otras categorías no implicará costo alguno.</p>

                </div>
            </article>

            <article className="d-flex flex-column justify-content-between px-3 px-md-5 mb-3">
                <h6 className="text-start fw-bold text-secondary py-4">CATEGORÍAS</h6>
                <div className="categorias__column mb-2">
                    <h6 className="fw-bold mb-2">Rugby</h6>
                    <p className="mb-1">Infantil (7 a 14 años) | Juvenil (14 a 19 años) | Plantel Superior Veteranos (+ de 25 años)</p>
                    <p className="mb-1">Ex-jugador socio protector</p>
                    <p className="mb-1">Ex-jugador socio pleno</p>
                    <p className="mb-1">Adherente (no haber jugado nunca)</p>
                    <p className="mb-1">Vitalicio</p>
                </div>
            </article>

            <article className="d-flex flex-column justify-content-between px-3 px-md-5 mb-3">
                <div className="mb-3">
                    <div className="categorias__column mb-1">
                        <h6 className="fw-bold mb-2">Hockey</h6>
                        <p className="mb-1">Inicial | Escuelita (3 a 8 años) | Infantil (9 a 14 años) | Juvenil (14 a 19 años) | Plantel Superior</p>
                        <p className="mb-1">Ex-jugadora socia protector</p>
                        <p className="mb-1">Ex-jugadora socia pleno</p>
                        <p className="mb-1">Adherente (no haber jugado nunca)</p>
                        <p className="mb-1">Vitalicia</p>
                    </div>
                </div>
                <div className="categorias__column">
                    <a href="#/contacto" className="socios__link fw-bold">Más Información</a>
                </div>

            </article>
            <article className="d-flex flex-column justify-content-between px-3 px-md-5">
                <h6 className="text-start fw-bold text-secondary py-4">BECA</h6>
                <div className="categorias__column mb-1">
                    <p>Aquellos socios o postulantes a socio que, por distintos motivos, se viera imposibilitados de afrontar el pago de sus compromisos
                        sociales (cuotas de ingreso y cuota ordinaria) deberán completar una Solicitud de Beca. La misma estará disponible en la Secretaría Administrativa.
                        Este pedido será analizado por la Comisión Directiva para luego dar respuesta a la solicitud.
                        <br />
                        Las becas serán otorgadas por un período anual.
                    </p>

                </div>
                <div className="categorias__column">
                    <a className="socios__link fw-bold" href="https://docs.google.com/forms/d/e/1FAIpQLSdSXOTR0vTltffIdKKGD2z7VWPwaiDimFWLc0_fAwM4ozthDQ/viewform" target="_blank">Formulario Beca</a>
                </div>
            </article>

        </section>
    )
}