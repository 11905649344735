import './style.css'
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaFacebook, FaInstagram, FaLocationDot, FaPhone, FaYoutube, FaTiktok } from 'react-icons/fa6';
import { IoMail } from "react-icons/io5";
import { confirmAlert } from 'react-confirm-alert';
import HeaderSpace from '../header-space';
import { SendMail } from '../../services/mailService';
import SpinnerLoading from '../spinner';
import { ConfirmModal } from '../../utils/confirmModal';

interface ConsultaProps {
    data: string;
    isModal?: boolean;
    destinatario?: string;
}

export default function Contacto({ data, isModal, destinatario }: ConsultaProps) {
    const [message, setMessage] = useState(data);
    const [loading, setLoading] = useState(false);
    const [instagram, setInstagram] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [telefono, setTelefono] = useState('');

    useEffect(() => {
        let parametros = localStorage.getItem("parametros") ? JSON.parse(localStorage.getItem("parametros")!) : {};

        let instagramParametro = parametros?.instagram || '';
        let whatsappParametro = parametros?.whatsapp || '';
        let telefonoParametro = parametros?.telefono || '';
        setMessage(data);
        setInstagram(instagramParametro);
        setWhatsapp(whatsappParametro);
        setTelefono(telefonoParametro);
    }, [])

    const { register, formState: { errors }, watch, handleSubmit } = useForm({
        defaultValues: {
            Nombre: '',
            Apellido: '',
            Email: '',
            Whatsapp: '',
            Destinatario: '',
            Asunto: 'Consulta Formulario Web',
            Mensaje: data,
            Datetime: ''
        }
    });

    useEffect(() => {
        setMessage(data);
    }, []);

    const enviarContacto = async (formData: any) => {
        setLoading(true);
        //let vDestinatario = await GetParametroByClave('Correo');
        let vDestinatario = destinatario || "secretaria@clubsanluis.com.ar"; //"maximiliano_hermosilla@hotmail.com"
        let today = new Date();
        formData = {
            ...formData,
            destinatario: vDestinatario,
            Datetime: today.toISOString(),
            idSistema: 8,
            Id: 0
        }
        let response = await SendMail(formData);
        setLoading(false);
        //console.log(response)
        ConfirmModal("Confirmación", response)


        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    return (
        <section className="container-fluid section__contacto">
            <div className="row py-4">
                {!isModal ? <div className="col-12 col-lg-6 text-start align-items-start mb-3">
                    <div className="col-12 text-center pt-0 pt-lg-5 mt-0 mt-lg-3">
                        <h3 className="d-block d-lg-none mb-5 fw-bold">Consultanos</h3>
                    </div>
                    <ul className="p-0">
                        <li>
                            <FaLocationDot className="contacto__icon mx-3" size={24}></FaLocationDot>
                            <div>
                                <h5>Ubicación</h5>
                                <p>520 2200, B1900 La Plata, Provincia de Buenos Aires</p>
                            </div>
                        </li>
                        <li>
                            <FaPhone className="contacto__icon mx-3" size={24}></FaPhone>
                            <div>
                                <h5>Teléfono</h5>
                                <p><a href="tel:+221220330" target="_blank">221-220330</a></p>
                            </div>
                        </li>
                        <li>
                            <IoMail className="contacto__icon mx-3" size={24}></IoMail>
                            <div>
                                <h5>Email</h5>
                                <p><a href="mailto:secretaria@clubsanluis.com.ar" target="_blank">secretaria@clubsanluis.com.ar</a></p>
                            </div>
                        </li>
                    </ul>
                    <div className="d-flex flex-row gap-1 justify-content-center py-4 mt-4 mb-3">
                        <a href="https://www.instagram.com/clubsanluis.ok?igsh=dDNreHIybDkxeTJ5" target="_blank"><FaInstagram className="social__icon mx-2" size={32}></FaInstagram></a>
                        <a href="https://web.facebook.com/clubsanluis.ok" target="_blank"><FaFacebook className="social__icon mx-2" size={32}></FaFacebook></a>
                        <a href="https://www.tiktok.com/@clubsanluis?_t=8qcTWpkn8QX&_r=1" target="_blank"><FaTiktok className="social__icon mx-2" size={32}></FaTiktok></a>
                    </div>
                </div> : ''}

                <form className={`col-12 ${isModal ? '' : 'col-lg-6'} d-flex justify-content-center`} onSubmit={handleSubmit(enviarContacto)}>
                    
                    <div className="row form__container">
                        <div className="col-12 text-center py-2 d-none d-lg-block">
                            <h3 className="fw-bold mb-3" >Consultanos</h3>
                        </div>
                        <div className="col-md-6">
                            <input
                                type="text"
                                {...register("Nombre", { required: true })}
                                id="contacto_nombre"
                                placeholder="* Nombre"
                                className="form-control input__form"
                            />
                            {errors.Nombre?.type === "required" && (
                                <p className="text-danger"> El campo nombre es requerido</p>
                            )}
                        </div>
                        <div className="col-md-6">
                            <input
                                type="text"
                                {...register("Apellido", { required: true })}
                                id="contacto_apellido"
                                placeholder="* Apellido"
                                className="form-control input__form"
                            />
                            {errors.Apellido?.type === "required" && (
                                <p className="text-danger"> El campo apellido es requerido</p>
                            )}
                        </div>
                        <div className="col-md-6">
                            <input
                                type="email"
                                {...register("Email", { required: true })}
                                id="contacto_email"
                                placeholder="* Email"
                                className="form-control input__form"
                            />
                            {errors.Email?.type === "required" && (
                                <p className="text-danger"> El campo email es requerido</p>
                            )}
                        </div>
                        <div className="col-md-6">
                            <input
                                type="text"
                                {...register("Whatsapp")}
                                id="contacto_telefono"
                                placeholder="WhatsApp (sin 0 ni 15)"
                                className="form-control input__form"
                            />
                        </div>
                        <div className="col-md-12">
                            <textarea
                                id="contacto_mensaje"
                                {...register("Mensaje")}
                                placeholder="Escriba aquí su mensaje"
                                className="form-control input__form"
                            ></textarea>
                        </div>
                        <div className="col-md-12 text-center">
                            <button
                                type="submit"
                                id="contacto_submit"
                                className="button__secondary my-3"
                            >
                                Enviar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {loading ? <SpinnerLoading></SpinnerLoading> : ''}
        </section>
    );
}