import Footer from '../../components/footer'
import Header from '../../components/header'
import TopBanner from '../../components/top-banner'
import RugbyPlanteles from './planteles'
import RugbySectionText from './section-text'
import './style.css'

export default function RugbyPage(){

    window.scrollTo(0, 0);
    
    return(
        <div className="div__home min-vh-100 h-10">
            <Header></Header>
            <TopBanner id={3}></TopBanner>
            <RugbySectionText></RugbySectionText>
            <RugbyPlanteles></RugbyPlanteles>
            <Footer></Footer>
        </div>
    )
}