import "./style.css";
import { useEffect, useRef, useState } from "react";
import icon from "../../media/img/logo/favicon-32x32.png"

//npm i @types/googlemaps

interface IMap {
    mapType: google.maps.MapTypeId;
    mapTypeControl?: boolean;
    latitud: number;
    longitud: number;
}

export default function Map({ mapType, mapTypeControl, latitud, longitud }: IMap) {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<any>();

    function initMap(zoomLevel: number, address: any): void {
        let centerMap = new google.maps.LatLng(-34.91042527565061, -58.00390960564615);
        let defaultAddress = new google.maps.LatLng(-34.91042527565061, -58.00390960564615);

        if (latitud) {
            centerMap = new google.maps.LatLng(latitud, longitud);
        }

        const map = new google.maps.Map(
            document.getElementById("map") as HTMLElement,
            {
                zoom: zoomLevel,
                center: centerMap,
                mapTypeControl: mapTypeControl,
                streetViewControl: false,
                rotateControl: false,
                scaleControl: false,
                panControl: false,
                zoomControl: true,
                gestureHandling: 'cooperative',
                mapTypeId: mapType,
                draggableCursor: 'pointer'
            }
        );

        const image = icon;
        const marker = new google.maps.Marker({
            position: defaultAddress,
            map,
            icon: image,
        });

        if (latitud) {
            const obraMarker = new google.maps.LatLng(latitud, longitud);
            const marker = new google.maps.Marker({
                position: obraMarker,
                map,
            });
        }

        // const buildMarker = new google.maps.Marker({
        //     position: new google.maps.LatLng(-34.90959065317686, -57.943767046036356),
        //     map,
        //     label: {color: '#FFF', fontSize: '12px', fontWeight: '600',
        //     text: '1'}
        //   });

        // const buildMarker2 = new google.maps.Marker({
        //     position: new google.maps.LatLng(-34.91341924025507, -57.9411073428999),
        //     map,
        //     label: {color: '#FFF', fontSize: '12px', fontWeight: '600',
        //     text: '2'}
        // });
    }

    const defaultMapStart = (): void => {
        const defaultAddress = new google.maps.LatLng(-34.9112453001979, -57.94108909217454);
        initMap(16, defaultAddress);
    }

    const startMap = (): void => {
        if (!map) {
            defaultMapStart();
        }
    }

    useEffect(startMap, [map]);

    return (
        <div className="map-container mapContainer">
            <div id="map" ref={ref} className="map-container__map mapContainerMap"></div>
        </div>
    );
}