import { Imagen } from "../interfaces/imagen";
import imgRugby from "../media/img/rugby_home.webp";
import imgHockey from "../media/img/Hockey-opcion-1.jpg";

export const MockDeportes: Imagen[] = 
[
    {
        id: 1,
        titulo: 'RUGBY',
        subtitulo: '',
        descripcion: '',
        imagenUrl: imgRugby,
        tipoImagen: {
            id: 1,
            descripcion: 'Banner'
        }
    },
    {
        id: 2,
        titulo: 'HOCKEY',
        subtitulo: '',
        descripcion: '',
        imagenUrl: imgHockey,
        tipoImagen: {
            id: 1,
            descripcion: 'Banner'
        }
    }
]