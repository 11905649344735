import "./style.css";
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";

interface InfoProps{
    info?: Imagen;
}

export default function CardBanner({info}: InfoProps){
    const [infoBanner, setInfo] = useState(info);

    useEffect(() => {
        setInfo(info);
    }, []);

    return(
        <div className='banner__container fill' style={{width: "100%"}}>  
            <div className="video-overlay no-click fill hide-for-medium"></div>
            <img id="banner_img" src={infoBanner?.imagenUrl} className='banner fill w-100'/>
            <div className="banner__caption">
                <h2>{infoBanner?.titulo}</h2>
            </div>
        </div>
    )
}