import "./style.css";
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";
import banner from "../../media/img/home_banner.png"
import { useNavigate } from "react-router-dom";

interface InfoProps {
    info?: Imagen;
}

export default function CardDeporte({ info }: InfoProps) {
    const [infoBanner, setInfo] = useState(info);

    useEffect(() => {
        setInfo(info);
    }, []);
    
    const navigate = useNavigate();

    const redirect = () => {
        navigate(`/${info?.titulo?.toLowerCase()}`);
        document.getElementById('btnToggleNavbar')?.click();
    }

    return (
        <article onClick={redirect} className='deportes__container col-12 col-lg-6'>
            <img src={infoBanner?.imagenUrl} className='deportes__img' />
            <div className="deportes__caption centered">
                <h2>{infoBanner?.titulo}</h2>
            </div>
        </article>
    )
}