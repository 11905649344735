import SectionText from "../../components/sectionText";
import { Imagen } from "../../interfaces/imagen";
import "./style.css";

export default function RugbySectionText(){

    const historia: Imagen = {
        id: 1,
        titulo: ``,
        subtitulo: '',
        descripcion: `Nuestra historia se inició con los Hermanos Maristas que entrenaban a nuestros jugadores y nos permitió practicar el rugby en el campo de deportes del Colegio durante más de veinte años.

A partir de la década del 80 y hasta la actualidad, el Club formó equipos competitivos integrados por jugadores que dedicaron muchos años de su vida adentro y afuera de la cancha, permitiendo de esta manera, continuar escribiendo nuestra gran historia. 

A su vez, fue y es imprescindible esos ex - jugadores, que convertidos en entrenadores, nos forman como personas, nos entrenan, acompañan y fundamentalmente nos transmiten ese sentido de pertenencia que tanto nos caracteriza.

Tenemos como objetivo, seguir apostando a nuestro nivel deportivo, pero por sobre todo, mantener y seguir cultivando nuestros valores que son nuestro pilar de crecimiento.
Nuestro sentido de pertenencia, transformó al club en nuestra casa, te invitamos a que seas parte.
Veni a conocer a los amigos del resto de tu vida.
`,
        imagenUrl: "",
        tipoImagen: {
            id: 3,
            descripcion: 'Seccion'
        }
    }

    return (
        <section id="nuestra-escencia" className="container-fluid pt-5 h-100">
            <h3 className="text-start px-3 px-md-5 fw-bold">Nuestra escencia</h3>
            <SectionText info={historia}></SectionText>
        </section>
    )
}