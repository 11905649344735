import Contacto from '../../components/contacto'
import Footer from '../../components/footer'
import Header from '../../components/header'
import HeaderSpace from '../../components/header-space'
import MapFooter from '../../components/map-footer'
import './style.css'

export default function ContactoPage(){

    window.scrollTo(0, 0);
    
    return(
        <div className="div__home min-vh-100 h-10">
            <Header></Header>
            <HeaderSpace></HeaderSpace>    
            {/* <Contacto data={''} destinatario="maximiliano_hermosilla@hotmail.com"></Contacto> */}
            <Contacto data={''} destinatario="secretaria@clubsanluis.com.ar"></Contacto>
            <MapFooter></MapFooter>
            <Footer></Footer>
        </div>
    )
}