import "./style.css"
import imgRugbySuperior from "../../media/img/rugby/plantel superior.webp"
import imgRugbyJuveniles from "../../media/img/rugby/juveniles.webp"
import imgRugbyInfantiles from "../../media/img/rugby/infantiles.webp"
import { useEffect, useState } from "react";
import { HeadCoach } from "../../interfaces/headCoach";
import { GetAllHeadCoachs } from "../../services/headCoachService";
import HeadCoachComponent from "../../components/headCoach";


export default function RugbyPlanteles() {
    const [headCoachs, setHeadCoach] = useState<HeadCoach[] | undefined>();

    useEffect(() => {        
        fetchHeadCoachs();
    }, [])
    
    const fetchHeadCoachs = async () => {
        let coaches = await GetAllHeadCoachs();
        setHeadCoach(coaches);
    }
    
    return (
        <section className="container-fluid">
            <div className="row">
                <article className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center gap-2 p-3">
                    <div className="article__planteles">
                        <img className="card__planteles__img rounded-3 mb-2" src={imgRugbySuperior} alt="Rugby Plantel Superior" width="320px" height="280px" />
                        <div className="mb-3">
                            <h5 className="title fw-bold my-2 w-auto">Plantel superior</h5>
                            <p>Divisiones Superiores</p>
                            <p>Torneo competitivo</p>
                        </div>
                        {headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 1)? <HeadCoachComponent data={headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 1)}></HeadCoachComponent>: ''}
                    </div>
                </article>
                <article className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center gap-2 p-3">
                    <div className="article__planteles">
                        <img className="card__planteles__img rounded-3 mb-2" src={imgRugbyJuveniles} alt="Rugby Plantel Superior" width="320px" height="280px"/>
                        <div className="mb-3">
                            <h5 className="title fw-bold my-2 w-auto">Juveniles</h5>
                            <p>M15 a M19 (15 a 19 años)</p>
                            <p>Torneo competitivo</p>
                        </div>
                        {headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 2)? <HeadCoachComponent data={headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 2)}></HeadCoachComponent>: ''}
                    </div>
                </article>
                <article className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center gap-2 p-3">
                    <div className="article__planteles">
                        <img className="card__planteles__img rounded-3 mb-2" src={imgRugbyInfantiles} alt="Rugby Plantel Superior" width="320px" height="280px" />
                        <div className="mb-3">
                            <h5 className="title fw-bold my-2 w-auto">Infantiles</h5>
                            <p>Escuelita a M14 (3 a 14 años)</p>
                            <p>Torneo recreativo</p>
                        </div>
                        {headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 3)? <HeadCoachComponent data={headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 3)}></HeadCoachComponent>: ''}
                    </div>
                </article>
            </div>
        </section>
    )
}