import { useState } from "react";
import { HeadCoach } from "../../interfaces/headCoach"
import "./style.css"

interface HeadCoachProps{
    data?: HeadCoach;
}

export default function HeadCoachComponent({data}: HeadCoachProps){
    const [headCoach, setHeadCoach] = useState<HeadCoach>(data!);

    return (
        <div className="d-flex flex-column gap-1 mb-2 coach__container">
            <h6 className="title fw-bold mb-2 w-auto">Head Coach</h6>            
            {headCoach?.nombre ?<p className="mb-1">{headCoach?.nombre}</p>: ''}
            {headCoach?.correo ?<a className="text-decoration-none" href={`mailto:${headCoach?.correo}`}>{headCoach?.correo}</a>: ''}
            {headCoach?.telefono ?<a className="text-decoration-none" href={`tel:${headCoach?.telefono}`}>{headCoach?.telefono}</a>: ''}
        </div>
    )
}