import "./style.css";
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";
import TrashIcon from "../../media/img/trash_light.png"

interface InfoProps {
    info?: Imagen;
    eliminarPartnerParent: any;
}

export default function CardPartnerAdmin({ info, eliminarPartnerParent }: InfoProps) {
    const [partner, setPartner] = useState<Imagen>();

    useEffect(() => {
        setPartner(info);
    }, []);

    function eliminarPartner(){
        eliminarPartnerParent(partner?.id)
    }

    return (
        <div className="partner__admin" onClick={eliminarPartner}>
            <img className="partner__img bg-secondary border border-1" src={partner?.url} width="150px" height="80px"></img>
            <img className="partner__img__hover bg-danger" src={TrashIcon} width="150px" height="80px"></img>
        </div>
    )
}