import { enviroment } from "../interfaces/enviroment";
import { Usuario } from "../interfaces/usuario";
import { ConfirmModal } from "../utils/confirmModal";


const BASE_URL: string = enviroment.urlBase() + "api/Login";
const ENV_DEMO: boolean = enviroment.demo();

export const AutenticarUsuario = async (data: Usuario): Promise<any> => {
    if (ENV_DEMO){
        return 'tokenDemo';
    }
    const url = `${BASE_URL}`;
    let result;

    try{
        const response = await fetch(url, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                //"Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })

        if (!response.ok){
            let errorResponse = await response.text();
            let errorJson = JSON.parse(errorResponse)
            ConfirmModal("Error",  errorJson.message);
            //throw new Error(errorResponse);        
            //return errorResponse;
        }
        
        if(response.ok){
            result = await response.json();
            return result.token;
        }  
    }
    catch (error: any){
        ConfirmModal("Error", "Ocurrió un error. Comuniquese con su administrador")
    }

}