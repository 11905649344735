import "./style.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";

interface SectionProps {
    info?: Imagen;
    inverse?: boolean;
    buttonLink?: string;
    buttonText?: string;
    idSeccion?: string;
}

export default function SectionText({ info, inverse, buttonLink, buttonText, idSeccion }: SectionProps) {
    const [infoSection, setInfo] = useState(info);

    useEffect(() => {
        setInfo(info);
    }, []);

    const navigate = useNavigate();

    const redirect = () => {
        navigate(`/${buttonLink}`);
    }

    const htmlString = () => (
        <div dangerouslySetInnerHTML={{ __html: infoSection?.descripcion || 'Holaaaaaaa' }} />
    );

    return (
        <section className="about" id={idSeccion}>            
            <div className="row d-flex text-center">
                {(infoSection?.titulo || infoSection?.subtitulo) ?
                <div className="w-100 text-center mb-3">
                    {infoSection?.titulo ? <h3 className="title text-center mb-2 fw-bold">{infoSection?.titulo}</h3> : ''}
                    {infoSection?.subtitulo ? <span className="subtitle">{infoSection?.subtitulo}</span> : ''}
                </div> : ''}

                <div className="col-12 d-flex align-items-center p-3 px-md-5">
                    <div className="section__descripcion">
                        {infoSection?.descripcion ? htmlString() : ''}
                    </div>
                </div>                
            </div>
        </section>
    )
}