import { enviroment } from "../interfaces/enviroment";
import { InstagramPost } from "../interfaces/instagramPost";
import { MockInstagramPosts } from "../mocks/instagramPosts";

const ENV_DEMO: boolean = enviroment.demo();
const BASE_URL: string = "https://graph.instagram.com/";
const USER_ID: string = "8074837665953476";
const ACCESS_TOKEN: string = "IGQWRQZAURNM1Y3cXVlMkpzQTJSa0poNWs1NFJDLXpCYTdjSGY2ektza1JkN0tSU19EdkJvVDlwN214RkhoN2I0QlFaNkp6dzZAtNndabVBjSkdESkJMRDZAwYU81aVg1ay03aXREZAkhmdFh6N0QwbTFadEw5UkhFZAnMZD";

interface InstagramJSON{
    data: any;
    pagin: any;
}

export const GetAllInstagramPosts = async (): Promise<InstagramPost[]> => {
    if (ENV_DEMO){
        //return MockInstagramPosts;
    }
    //return MockInstagramPosts;        
    const url = `${BASE_URL}${USER_ID}/media?access_token=${ACCESS_TOKEN}&fields=media_url,permalink,media_type`;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers:{
                "Access-Control-Allow-Origin": "*",
            }
        });
        if (!response.ok){
            throw new Error(response.statusText);        
        }
        const data: InstagramJSON = await response.json();
        const posts: InstagramPost[] = data?.data;
        return posts;
    } catch (error) {
        return MockInstagramPosts;
    }    
}