import Router from './Router';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <Router></Router>
  )
}

export default App;
