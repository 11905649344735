import { Empleado } from "../interfaces/empleado";

export const MockEmpleados: Empleado[] = 
[
    {
        id: 1,
        nombre: 'Sr. Diego Fornesi',        
        tipoEmpleado: {
            id: 1,
            descripcion: 'Presidente'
        },
        idTipoEmpleado: 1
    },
    {
        id: 2,
        nombre: 'Sr. Lucas Noms',        
        tipoEmpleado: {
            id: 2,
            descripcion: 'Vicepresidente'
        },
        idTipoEmpleado: 2
    },
    {
        id: 3,
        nombre: 'Sr. Conrado Carrasco Quintana',        
        tipoEmpleado: {
            id: 3,
            descripcion: 'Secretario'
        },
        idTipoEmpleado: 3
    },
    {
        id: 4,
        nombre: 'Sr. Sebastian Russo',        
        tipoEmpleado: {
            id: 4,
            descripcion: 'Tesorero'
        },
        idTipoEmpleado: 4
    },
    {
        id: 5,
        nombre: 'Sr. Guillermo Bethular',        
        tipoEmpleado: {
            id: 5,
            descripcion: 'Pro Secretario'
        },
        idTipoEmpleado: 5
    },
    {
        id: 6,
        nombre: 'Sr. Mario Casas',        
        tipoEmpleado: {
            id: 6,
            descripcion: 'Pro Tesorero'
        },
        idTipoEmpleado: 6
    },

    {
        id: 7,
        nombre: 'Sr. Sebastian Crispo',        
        tipoEmpleado: {
            id: 7,
            descripcion: 'Vocales titulares'
        },
        idTipoEmpleado: 7
    },
    {
        id: 8,
        nombre: 'Sr. Alberto Plot',        
        tipoEmpleado: {
            id: 7,
            descripcion: 'Vocales titulares'        
        },
        idTipoEmpleado: 7
    },
    {
        id: 9,
        nombre: 'Sr. Jorge Gibert',        
        tipoEmpleado: {
            id: 7,
            descripcion: 'Vocales titulares'
        },
        idTipoEmpleado: 7
    },
    {
        id: 10,
        nombre: 'Sr. Mateo Vescuso',        
        tipoEmpleado: {
            id: 8,
            descripcion: 'Vocales suplentes'
        },
        idTipoEmpleado: 8
    },
    {
        id: 11,
        nombre: 'Sr. Zeljko Rozankovik',        
        tipoEmpleado: {
            id: 8,
            descripcion: 'Vocales suplentes'
        },
        idTipoEmpleado: 8
    },
    {
        id: 12,
        nombre: 'Sr. Gustavo Algeri',        
        tipoEmpleado: {
            id: 8,
            descripcion: 'Vocales suplentes'
        },
        idTipoEmpleado: 8
    },
    {
        id: 13,
        nombre: 'Sr. Juan Cruz Condomí Alcorta',        
        tipoEmpleado: {
            id: 8,
            descripcion: 'Vocales suplentes'
        },
        idTipoEmpleado: 8
    },
    {
        id: 14,
        nombre: 'Sr. Mariano Dacal',        
        tipoEmpleado: {
            id: 9,
            descripcion: 'Revisores de cuentas'
        },
        idTipoEmpleado: 9
    },
    {
        id: 15,
        nombre: 'Sr. Nicolas Monsalve',        
        tipoEmpleado: {
            id: 9,
            descripcion: 'Revisores de cuentas'
        },
        idTipoEmpleado: 9
    },
    {
        id: 16,
        nombre: 'Sr. Maximiliano Cazeneuve',        
        tipoEmpleado: {
            id: 9,
            descripcion: 'Revisores de cuentas'
        },
        idTipoEmpleado: 9
    },
    {
        id: 17,
        nombre: 'Sr. Jorge Bizet',        
        tipoEmpleado: {
            id: 9,
            descripcion: 'Revisores de cuentas'
        },
        idTipoEmpleado: 9
    },
    {
        id: 18,
        nombre: 'Sr. Federico Ordoqui',        
        tipoEmpleado: {
            id: 9,
            descripcion: 'Revisores de cuentas'
        },
        idTipoEmpleado: 9
    },
    {
        id: 19,
        nombre: 'Sr. Nestor Galán',        
        tipoEmpleado: {
            id: 7,
            descripcion: 'Vocales titulares'
        },
        idTipoEmpleado: 7
    },
]