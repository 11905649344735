import { SubmitHandler, useForm } from "react-hook-form";
import "./style.css"
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";
import { DeleteImage, GetAllImagesByType, InsertImage } from "../../services/imagenService";
import { IoMdRefresh } from "react-icons/io";
import { TipoImagen } from "../../interfaces/tipoImagen";
import CardFixtureAdmin from "../../components/cardFixtureAdmin";
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmModal } from "../../utils/confirmModal";
import { UploadImageCloudinary } from "../../services/cloudinaryService";
import SpinnerLoading from "../../components/spinner";
import { useAuth } from "../../utils/authContext";
    
type FormDataRugby = {
    nameRugby: string;
    imagenFixtureRugby: FileList;
}
    
type FormDataHockey = {
    nameHockey: string;
    imagenFixtureHockey: FileList;
};

export default function AdminFixtures(){
    const [loading, setLoading] = useState(false);
    const [fileRugby, setFileRugby] = useState<any>();
    const [fileHockey, setFileHockey] = useState<any>();
    const [imagenRugby, setImagenRugby] = useState<any>();
    const [imagenHockey, setImagenHockey] = useState<any>();
    const [fixturesRugby, setFixturesRugby] = useState<Imagen[]>();
    const [fixturesHockey, setFixturesHockey] = useState<Imagen[]>();
    const [token, setToken] = useState<string>('');
    const auth = useAuth();
    
    useEffect(() => {
        setToken(auth.token);     
        fetchFixtures();
    }, [])
    
    const fetchFixtures = async () => {
        let imagenesRugby = await GetAllImagesByType(2);
        let imagenesHockey = await GetAllImagesByType(3);
        setFixturesRugby(imagenesRugby);
        setFixturesHockey(imagenesHockey);
    }
    const imgDefault = "https://placehold.co/400x800";
    
    const { register: registerRugby, handleSubmit: handleSubmitRugby, formState: { errors: errorsRugby } } = useForm<FormDataRugby>({ });
    const { register: registerHockey, handleSubmit: handleSubmitHockey, formState: { errors: errorsHockey } } = useForm<FormDataHockey>({ });

    async function handleChangeRugby(e: any) {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        if (file) {
            setFileRugby(e.target.files[0])
            setImagenRugby(URL.createObjectURL(e.target.files[0]))
        }
    }

    async function handleChangeHockey(e: any) {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        if (file) {
            setFileHockey(e.target.files[0])
            setImagenHockey(URL.createObjectURL(e.target.files[0]))
        }
    }

    const onSubmitRugby: SubmitHandler<FormDataRugby> = data => {
        if(data && data.imagenFixtureRugby[0]){
            //console.log('File:', data.imagenFixtureRugby[0]);
            guardarImagen(data.imagenFixtureRugby[0].name, data.imagenFixtureRugby, {id: 2, descripcion: "Fixture Rugby"});
        }
    };
    
    const onSubmitHockey: SubmitHandler<FormDataHockey> = data => {
        if(data && data.imagenFixtureHockey[0]){
            //console.log('File:', data.imagenFixtureHockey[0]);        
            guardarImagen(data.imagenFixtureHockey[0].name, data.imagenFixtureHockey, {id: 3, descripcion: "Fixture Hockey"});
        }
    };

    function guardarImagen(name: string, imagenFile: any, tipo: TipoImagen){
        setLoading(true);
        try {
            let result: any;
            const file = imagenFile[0];
            const blob = new Blob([file], { type: file.type });

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
                let base64String = reader.result;
                const jsonData = JSON.stringify({ pdfFile: base64String });
                
                let response = await UploadImageCloudinary(file, "fixtures");
                base64String = response;
        
                InsertImage({ id: undefined, nombre: name, url: base64String, tipoImagen: tipo, idTipoImagen: tipo.id }, token);
                setFixturesHockey([])
                setFixturesRugby([])
                setImagenHockey(imgDefault);
                setImagenRugby(imgDefault);
                ConfirmModal("Confirmación", "Imagen subida correctamente");
                setTimeout(() => {
                    fetchFixtures();
                }, 1500);
            }
        } catch (error) {
            ConfirmModal("Error", "Se produjo un error al guardar la imagen.")
        }
        
        setLoading(false);
    }

    function eliminarFixture(id: number){
        confirmAlert({
            title: "Administración",
            message: `¿Está seguro de que desea eliminar el fixture?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                    },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: async () => 
                    {
                        let response = await DeleteImage(id, token);
                        ConfirmModal("Confirmación", "Se eliminó la imagen correctamente")
                        setFixturesHockey(fixturesHockey?.filter((e: any) => e.id != id))
                        setFixturesRugby(fixturesRugby?.filter((e: any) => e.id != id))
                    }
                }
            ]
        });
    }
    
    function reset(){
        confirmAlert({
            title: "Administración",
            message: `¿Desea restablecer los valores por defecto?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                    },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: () => 
                    {
                        localStorage.removeItem("imagenes");
                        setTimeout(() => {                         
                            window.location.reload();          
                        }, 500);
                    }
                }
            ]
        });
    }

    const renderFixtures = (imagenes: any[]) => imagenes.map((v: any, i: any) => <CardFixtureAdmin key={v.id || i} info={v} eliminarFixtureParent={eliminarFixture}></CardFixtureAdmin>);
    
    return (
        <div>
            <form className="container__documento container my-3 form-group w-100" onSubmit={handleSubmitRugby(onSubmitRugby)}>
                <label htmlFor="" className="my-1"><strong>Aclaración</strong>: Las imágenes deben tener una relación de aspecto de 1:2 aproximadamente para su correcta visualización.</label>
                <br />
                <label className="form-label mt-4 fw-bold">Rugby</label>
                <div className="container__documento d-flex align-items-center gap-3">
                    <input className="form-control border border-primary h-25" type="file" id="formFile" 
                    {...registerRugby("imagenFixtureRugby")} accept="image/*" onChange={handleChangeRugby} />
                    {errorsRugby.imagenFixtureRugby?.type === 'required' && <p className="text-danger"> El archivo es requerido</p>}
                    <button className="button__secondary my-2" type="submit">Guardar</button>
                </div>
                <div className="d-flex gap-2">
                    {fixturesRugby? renderFixtures(fixturesRugby): ''}
                    <img height="200px" src={imagenRugby || imgDefault} alt="imagenRugby" />
                </div>
            </form>

            <form className="container__documento container my-3 form-group w-100" onSubmit={handleSubmitHockey(onSubmitHockey)}>
                <label className="form-label mt-4 fw-bold">Hockey</label>
                <div className="container__documento d-flex align-items-center gap-3">
                    <input className="form-control border border-primary h-25" type="file" id="formFile" 
                    {...registerHockey("imagenFixtureHockey")} accept="image/*" onChange={handleChangeHockey} />
                    {errorsHockey.imagenFixtureHockey?.type === 'required' && <p className="text-danger"> El archivo es requerido</p>}                    
                    <button className="button__secondary my-2" type="submit">Guardar</button>
                </div>
                <div className="d-flex gap-2">
                    {fixturesHockey? renderFixtures(fixturesHockey): ''}
                    <img height="200px" src={imagenHockey || imgDefault} alt="imagenHockey" />
                </div>
            </form>

            <div className="container my-5 pb-3 d-flex justify-content-center">
                {/* <button id="button-guardar-head-coachs" className="button__primary" onClick={reset}><IoMdRefresh></IoMdRefresh></button> */}
            </div>            
            {loading ? <SpinnerLoading></SpinnerLoading> : ''}
        </div>
    )
}