import SectionText from "../../components/sectionText";
import { Imagen } from "../../interfaces/imagen";
import "./style.css";

export default function ClubHistoriaAscenso(){

    const historia: Imagen = {
        id: 1,
        titulo: ``,
        subtitulo: '',
        descripcion: `El año 1975 resultó muy especial ya que comenzó con la primera gira a Europa y finalizó con el ascenso a la 1ra. División del Rugby de Buenos Aires. Sin embargo, la reestructuración de la UAR en 1978 generó nuestro descenso a la 2da. División, lugar que ocupamos durante más de una década.
`,
        imagenUrl: "",
        tipoImagen: {
            id: 3,
            descripcion: 'Seccion'
        }
    }

    return (
        <section id="club-historia-ascenso" className="container-fluid pt-5 px-1 px-md-3 h-100 historia__ascenso__container">
            <h5 className="text-start px-3 px-md-5 fw-bold">Los comienzos. El ascenso de 1975</h5>
            <SectionText info={historia}></SectionText>
        </section>
    )
}