import "./style.css";
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";
import TrashIcon from "../../media/img/trash_light.png"

interface InfoProps {
    info?: Imagen;
    eliminarFixtureParent: any;
}

export default function CardFixtureAdmin({ info, eliminarFixtureParent }: InfoProps) {
    const [fixture, setFixture] = useState<Imagen>();

    useEffect(() => {
        setFixture(info);
    }, []);

    function eliminarFixture(){
        eliminarFixtureParent(fixture?.id)
    }

    return (
        <div className="fixture__admin" onClick={eliminarFixture}>
            <img className="fixture__img" src={fixture?.url} width="100px" height="200px"></img>
            <img className="fixture__img__hover bg-danger" src={TrashIcon} width="100px" height="200px"></img>
        </div>
    )
}