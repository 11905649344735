import "./style.css"
import imgHockeySuperior from "../../media/img/hockey/plantel superior.webp";
import imgHockeyJuveniles from "../../media/img/hockey/juveniles.webp";
import imgHockeyInfantiles from "../../media/img/hockey/infantiles.webp"
import { useEffect, useState } from "react";
import { HeadCoach } from "../../interfaces/headCoach";
import { GetAllHeadCoachs } from "../../services/headCoachService";
import HeadCoachComponent from "../../components/headCoach";


export default function HockeyPlanteles() {
    const [headCoachs, setHeadCoach] = useState<HeadCoach[] | undefined>();

    useEffect(() => {        
        fetchHeadCoachs();
    }, [])
    
    const fetchHeadCoachs = async () => {
        let coaches = await GetAllHeadCoachs();
        setHeadCoach(coaches);
    }

    return (
        <section className="container-fluid">
            <div className="row">
                <article className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center gap-2 p-3">
                    <div className="article__planteles">
                        <img className="card__planteles__img rounded-3 mb-2" src={imgHockeySuperior} alt="Hockey Plantel Superior" width="320px" height="280px" />
                        <div className="mb-4">
                            <h5 className="fw-bold my-2 w-auto">Plantel superior</h5>
                            <p>Divisiones Superiores</p>
                            <p>Tira A - B - C</p>
                            <p>Torneo competitivo</p>
                        </div>
                        {headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 1)? <HeadCoachComponent data={headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 1)}></HeadCoachComponent>: ''}
                    </div>
                </article>
                <article className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center gap-2 p-3">
                    <div className="article__planteles">
                        <img className="card__planteles__img rounded-3 mb-2" src={imgHockeyJuveniles} alt="Hockey Plantel Superior" width="320px" height="280px"/>
                        <div className="mb-4">
                            <h5 className="fw-bold my-2 w-auto">Juveniles</h5>
                            <p>15 a 19 años</p>
                            <p>Tira A - B - C</p>
                            <p>Torneo competitivo</p>
                        </div>
                        {headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 2)? <HeadCoachComponent data={headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 2)}></HeadCoachComponent>: ''}
                    </div>
                </article>
                <article className="col-12 col-lg-4 d-flex flex-column justify-content-center align-items-center gap-2 p-3">
                    <div className="article__planteles">
                        <img className="card__planteles__img rounded-3 mb-2" src={imgHockeyInfantiles} alt="Hockey Plantel Superior" width="320px" height="280px" />
                        <div className="mb-4">
                            <h5 className="fw-bold my-2 w-auto">Infantiles</h5>
                            <p>3 a 14 años</p>
                            <p>Tira A - B - C</p>
                            <p>Torneo recreativo hasta 10 años</p>
                        </div>
                        {headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 3)? <HeadCoachComponent data={headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 3)}></HeadCoachComponent>: ''}
                    </div>
                </article>
            </div>
        </section>
    )
}