import React from 'react'
import { Cloudinary, CloudinaryImage } from '@cloudinary/url-gen';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';
import { backgroundRemoval } from "@cloudinary/url-gen/actions/effect";
//new CloudinaryImage("me/bgr-apparel-1.jpg.png").effect(backgroundRemoval());

export const UploadImageCloudinary = async (data: any, type: string): Promise<any> => {
    let result;
    const formData = new FormData();
    formData.append('file', data);
    formData.append('upload_preset', `clubsanluis-${type}`);

    try {
        const response = await fetch("https://api.cloudinary.com/v1_1/dundcrnii/image/upload", {
            method: "POST",
            body: formData,
        });       
        
        result = await response.json();
        if(response.ok){
            return result.secure_url;
        }   
    } catch (error) { 
        return undefined;
    }
}