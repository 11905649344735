import Footer from '../../components/footer'
import Header from '../../components/header'
import HomeInstagram from '../../components/instagram/instagram'
import TopBanner from '../../components/top-banner'
import HomeDeportes from './deportes'
import HomeFixtures from './fixtures'
import HomeMarcas from './marcas'
import './style.css'

export default function HomePage(){

    return(
        <div className="div__home min-vh-100 h-10">
            <Header></Header>
            <TopBanner id={1}></TopBanner>
            <HomeDeportes></HomeDeportes>
            <HomeMarcas></HomeMarcas>
            <HomeFixtures></HomeFixtures>
            <HomeInstagram></HomeInstagram>
            <Footer></Footer>
        </div>
    )
}