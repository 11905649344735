import SectionText from "../../components/sectionText";
import { Imagen } from "../../interfaces/imagen";
import "./style.css";

export default function ClubHistoriaOrigenes(){

    const historia: Imagen = {
        id: 1,
        titulo: ``,
        subtitulo: '',
        descripcion: `El 15 de diciembre de 1961 se funda el Club San Luis resultado de la practica de rugby y la participación en torneos que jugaban los alumnos del secundario del Colegio San Luis, creado por los hermanos maristas en la Ciudad de La Plata.

Luego en el año 1962 fuimos inscriptos para poder anotar al Club en la Union Argentina de Rugby (UAR), siendo nuestros padrinos Pueyrredon y Biei.
En ese entonces el torneo de Buenos Aires lo organizaba la Comisión de Buenos Aires, que respondía a la Unión Argentina de Rugby.
`,
        imagenUrl: "",
        tipoImagen: {
            id: 3,
            descripcion: 'Seccion'
        }
    }

    return (
        <section id="nuestros-origenes" className="container-fluid pt-5 px-1 px-md-3 h-100">
            <h3 className="text-center px-3 px-md-5 fw-bold">Nuestros orígenes</h3>
            <SectionText info={historia}></SectionText>
        </section>
    )
}