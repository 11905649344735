import "./style.css"
import { useEffect, useState } from 'react';
import Header from '../../components/header'
import { AutenticarUsuario } from '../../services/usuarioService';
import HeaderSpace from '../../components/header-space';
import LoginForm from '../login/login-form';
import Administracion from "./administracion";
import Copyright from "../../components/copyright";
import { useAuth } from "../../utils/authContext";
import { Navigate } from "react-router-dom";


export default function AdminPage(){
    const [token, setToken] = useState('');
    const [message, setMessage] = useState();
    const auth = useAuth();

    useEffect(() => {
        setToken(auth.token);
    }, [])
        
    async function login(formData: any){
        auth.login(formData);
        // let result = '';
        // try {
        //     result = await AutenticarUsuario(formData);    
        //     if (result){
        //         //console.log(result);
        //         localStorage.setItem("token", JSON.stringify(result?.replace(/['"]+/g, '')));
        //         setToken(result);
        //     }        
        // } catch (error: any) {
        //     //console.log(error.message);
        //     setMessage(error.message);
        //     let btnConfirmar = document.getElementById(`btnModalError`);
        //     btnConfirmar?.click();
        // }    
    }

    return(
        <div className="div__home min-vh-100 container__admin pb-0">
            <Header></Header>
            <HeaderSpace></HeaderSpace>
            {token==''?<LoginForm data={{}} login={login}></LoginForm>: ''}
            {token!=''?<Administracion></Administracion>: ''}
            <Copyright></Copyright>
        </div>
    )
}