import AdminPage from "../pages/admin";
import ClubPage from "../pages/club";
import ContactoPage from "../pages/contacto";
import DocumentosPage from "../pages/documentos";
import HockeyPage from "../pages/hockey";
import HomePage from "../pages/home";
import LoginPage from "../pages/login";
import RugbyPage from "../pages/rugby";

export const InicioRoute = () => <HomePage></HomePage>
export const LoginRoute = () => <LoginPage></LoginPage>
export const AdminRoute = () => <AdminPage></AdminPage>
export const ContactoRoute = () => <ContactoPage></ContactoPage>
export const ClubRoute = () => <ClubPage></ClubPage>
export const DocumentosRoute = () => <DocumentosPage></DocumentosPage>
export const HockeyRoute = () => <HockeyPage></HockeyPage>
export const RugbyRoute = () => <RugbyPage></RugbyPage>