import { enviroment } from "../interfaces/enviroment";
import { UnauthorizedLogout } from "../utils/unauthorizedLogout";
import { MockHeadCoachs } from "../mocks/headCoachs";
import { HeadCoach } from "../interfaces/headCoach";

const BASE_URL: string = enviroment.urlBase() + "api/HeadCoach";
const ENV_DEMO: boolean = enviroment.demo();
let headCoachs = localStorage.getItem("headCoachs") ? JSON.parse(localStorage.getItem("headCoachs")!) : undefined;

export const GetAllHeadCoachs = async (): Promise<HeadCoach[]> => {
    if (ENV_DEMO){
        if (headCoachs === undefined){
            headCoachs = MockHeadCoachs;
            localStorage.setItem("headCoachs", JSON.stringify(headCoachs));
        }
        return headCoachs;
    }
    const url = `${BASE_URL}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: HeadCoach[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const GetAllHeadCoachsByType = async (seccionId?: number): Promise<HeadCoach[]> => {
    if (ENV_DEMO){
        return headCoachs;
    }
    const url = `${BASE_URL}/${seccionId}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: HeadCoach[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const InsertHeadCoach= async (data: any, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    
    const url = `${BASE_URL}`;
    let result;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        result = await response.json();
        if(response.ok && response.status == 201){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const UpdateHeadCoach = async (data: any, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    
    const url = `${BASE_URL}`;   
    let result;
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        //console.log(response);
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);
        }
        result = await response.json();
        if(response.ok && response.status == 200){
            //console.log(result)
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const DeleteHeadCoach = async (id: number, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }

    const url = `${BASE_URL}/${id}`;
    let result;
    try {
        const response = await fetch(url, {
            method: "DELETE",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(id) 
        })        
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);
        }   
        
        if(response.ok){
            return response;
        }     
    } catch (error) {
        return undefined;
    }    
}