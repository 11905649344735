import SectionText from "../../components/sectionText";
import { Imagen } from "../../interfaces/imagen";
import "./style.css";

export default function ClubHistoriaInstitucional(){

    const historia: Imagen = {
        id: 1,
        titulo: ``,
        subtitulo: '',
        descripcion: `Los años 80 se caracterizan por la consolidación institucional y el crecimiento en infraestructura deportiva y social, lo que se ve materializado con la compra en el año 1985 del predio de seis hectáreas entre las calles 520 y 522 de 27 a 29.

Así en el año 1989 se inauguró la cancha N ° 1 de rugby del Club que lleva el nombre Luis Maria Manes, disputando la Primera un partido amistoso contra el Club Nimes de Francia.

En 1992 el Plantel Superior del Club se fue de gira a Nueva Zelanda y el mismo año se logró el primer ascenso a la primera división del rugby de Buenos Aires.

Luego, tras un paso fugaz por la segunda división, la Primera asciende nuevamente en el año 1994 a la máxima división de la URBA, lugar que ocupa ininterrumpidamente desde esa fecha hasta la actualidad.

En el año 1998 nos consagramos campeones del Nacional de Clubes en la final recordada con San Cirano en la cancha del CASI.
`,
        imagenUrl: "",
        tipoImagen: {
            id: 3,
            descripcion: 'Seccion'
        }
    }

    return (
        <section id="club-historia-ascenso" className="container-fluid pt-5 px-1 px-md-3 h-100 historia__ascenso__container">
            <h5 className="text-start px-3 px-md-5 fw-bold">La consolidación institucional en los 80’ y el crecimiento deportivo en los 90’</h5>
            <SectionText info={historia}></SectionText>
        </section>
    )
}