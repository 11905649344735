import { useEffect, useState } from 'react';
import './style.css'
import { useForm } from 'react-hook-form';
import { Empleado } from '../../interfaces/empleado';
import { FaCheck, FaRegTrashAlt } from "react-icons/fa";

interface InputProps {
    data: Empleado;
    eliminarEmpleadoChild: any;
    actualizarEmpleadoChild: any;
}

export default function InputEmpleado({ data, eliminarEmpleadoChild, actualizarEmpleadoChild }: InputProps) {
    const [empleado, setEmpleado] = useState<Empleado>(data);
    const [empleadoId, setEmpleadoId] = useState<any>();

    useEffect(() => {
        setEmpleado(data);
        setEmpleadoId(`${data?.tipoEmpleado?.descripcion?.replaceAll(" ", "")}_${data?.id}`)
    }, [])

    function changeEmpleado(e: any) {
        setEmpleado({ ...empleado, nombre: e.target.value })
        //actualizarEmpleadoChild({ ...empleado, nombre: e.target.value })
    }

    function eliminarEmpleado() {
        eliminarEmpleadoChild(data?.id);
    }

    function guardarEmpleado() {
        if(empleado?.nombre != ""){
            //console.log(empleado);
            actualizarEmpleadoChild(empleado)
        }
        else{
            alert("El campo no puede estar vacío")
        }
    }

    return (
        <div className="container my-3">
            <label className="text-left">{empleado?.tipoEmpleado?.descripcion}</label>
            <div className="mb-3 input-group">
                <input type="text" placeholder="Nombre y Apellido" className={`form-control rounded-0 ${empleado?.id == 0 ? 'input__new' : ''}`}
                    id={empleadoId} value={empleado?.nombre} onChange={changeEmpleado} required/>
                <div className="input-group-append">
                    <button className="btn btn-outline-success rounded-0 button__guardar__comision" type="button" onClick={guardarEmpleado}><FaCheck></FaCheck></button>
                </div>
            </div>
        </div>
    )

}