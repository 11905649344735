import "./style.css"
import { loadMapApi } from "../../utils/googleMaps";
import { useEffect, useState } from "react";
import Map from "../map";

interface IMap {
    mapType: google.maps.MapTypeId;
    mapTypeControl?: boolean;
}

interface MapProps {
    latitud?: string;
    longitud?: string;
}

export default function MapFooter({ latitud, longitud }: MapProps) {
    const [lat, setLatitud] = useState<string>(latitud!);
    const [long, setLongitud] = useState<string>(longitud!);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const googleMapScript = loadMapApi();
        setScriptLoaded(true);
        // googleMapScript.addEventListener('load', function () {
        //     setScriptLoaded(true);
        // });
    }, []);

    return (
        <div className="
        ">           
            {scriptLoaded && (
                <Map
                    mapType={google.maps.MapTypeId.ROADMAP}
                    mapTypeControl={true}
                    latitud={Number(latitud)}
                    longitud={Number(longitud)}
                />
            )}
        </div>
    );
}