import { Imagen } from "../../interfaces/imagen";
import "./style.css";

export default function ClubExPresidentes(){

    const eventos: Imagen[] = [
        {
            descripcion: "Ernesto Coppola (1961-1965)"
        },
        {
            descripcion: "Daniel Almeida (1965 - 1968)"
        },
        {
            descripcion: "Gerardo Villadeamigo (1968-1993)"
        },
        {
            descripcion: "Enrique Tercero Saravi (1993-1999)"
        },
        {
            descripcion: "Alejandro Campodónico (1999-2003)"
        },
        {
            descripcion: "Alejandro Campodónico (2005-2009)"
        },
        {
            descripcion: "Alfredo Gascon (2003-2005)"
        },
        {
            descripcion: "Nestor Gullo (2009-2011)"
        },
        {
            descripcion: "Juan Martin Etchart Mandon (2011-2015)"
        },
        {
            descripcion: "Alejandro Mamblona (2015-2019)"
        },
        {
            descripcion: "Marcelo Campodonico (2019-2023)"
        },
    ]

    const renderPresidentes = () => eventos?.map((v, i) => <article className="ps-0 ps-md-5 mx-auto" key={i}><div className="expresidente__container"><h3 className="title fw-bold">{v?.titulo}</h3><p className="section__descripcion">{v?.descripcion}</p></div></article>)

    return (
        <section className="py-5 mb-5">
            <h3 className="text-center px-3 px-md-5 mb-5 fw-bold">Nuestros Ex Presidentes</h3>
            <div className="presidentes__container container-fluid">
                {eventos? renderPresidentes():''}
            </div>
        </section>
    )
}