import "./style.css"
import { Documento } from "../../interfaces/documento"
import { useState } from "react";

interface DocumentoProps {
    documento: Documento;
}

export default function CardDocumento({ documento }: DocumentoProps) {
    const [archivo, setArchivo] = useState<Documento>(documento);

    const viewBrochure = async () => {
        const url = archivo?.url!;
        let alink = document.createElement("a");
        alink.href = url;
        alink.target = "_blank";
        alink.rel = "noreferrer"
        alink.click();
    }

    return (
        <li>
            <a className="documento__link" onClick={viewBrochure}>{archivo?.nombre}</a>
        </li>
    )
}