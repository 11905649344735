import { useEffect, useState } from "react";
import "./style.css"
import { SubmitHandler, useForm } from "react-hook-form"
import { Imagen } from "../../interfaces/imagen";
import { DeleteImage, GetAllImagesByType, InsertImage } from "../../services/imagenService";
import { IoMdRefresh } from "react-icons/io";
import { TipoImagen } from "../../interfaces/tipoImagen";
import CardPartnerAdmin from "../../components/cardPartnerAdmin";
import { UploadImageCloudinary } from "../../services/cloudinaryService";
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmModal } from "../../utils/confirmModal";
import { useAuth } from "../../utils/authContext";

type FormData = {
    imagenSponsor: FileList;
}

export default function AdminSponsors() {
    const [marcas, setMarcas] = useState<Imagen[]>();
    const [file, setFile] = useState<any>();
    const [imagen, setImagen] = useState<any>();
    const [token, setToken] = useState<string>('');
    const auth = useAuth();

    const imgDefaultSponsor = "https://placehold.co/200x100";

    useEffect(() => {
        setToken(auth.token);
        fetchSponsors();
    }, [])

    const fetchSponsors = async () => {
        let imagenes = await GetAllImagesByType(4);
        setMarcas(imagenes);
    }

    const { register, handleSubmit, formState: { errors } } = useForm<FormData>({});


    async function handleChange(e: any) {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        if (file) {
            setFile(e.target.files[0])
            setImagen(URL.createObjectURL(e.target.files[0]))
        }
    }

    const onSubmit: SubmitHandler<any> = data => {
        if(data && data.imagenSponsor[0]){
            guardarImagen(data.imagenSponsor[0].name, data.imagenSponsor, { id: 4, descripcion: "Sponsor" });
        }
    };

    async function guardarImagen(name: string, imagenFile: any, tipo: TipoImagen) {
        try{
            let result: any;
            const file = imagenFile[0];
            const blob = new Blob([file], { type: file.type });

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
                let base64String = reader.result;
                const jsonData = JSON.stringify({ pdfFile: base64String });
                let response = await UploadImageCloudinary(file, "sponsors");
                base64String = response;
                result = await InsertImage({ id: undefined, nombre: name, url: base64String, tipoImagen: tipo, idTipoImagen: tipo.id }, token);
                setMarcas([])
                setImagen(imgDefaultSponsor);
                ConfirmModal("Confirmación", "Imagen subida correctamente");
                setTimeout(() => {
                    fetchSponsors();
                    setMarcas([...marcas!, result]);
                }, 1500);
            }

        } catch (error) {
            ConfirmModal("Error", "Se produjo un error al guardar la imagen.")
        }
    }

    function eliminarPartner(id: number) {
        confirmAlert({
            title: "Administración",
            message: `¿Está seguro de que desea eliminar el sponsor?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                    },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: async () => 
                    {
                        let response = await DeleteImage(id, token);
                        ConfirmModal("Confirmación", "Se eliminó la imagen correctamente")
                        setMarcas(marcas?.filter((e: any) => e.id != id))
                    }
                }
            ]
        });
    }

    function reset() {
        confirmAlert({
            title: "Administración",
            message: `¿Desea restablecer los valores por defecto?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                    },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: () => 
                    {
                        localStorage.removeItem("imagenes");
                        setTimeout(() => {                         
                            window.location.reload();          
                        }, 500);
                    }
                }
            ]
        });
    }

    const renderImagenes = (imagenes: any[]) => imagenes.map((v: any, i: any) => <CardPartnerAdmin key={v.id || i} info={v} eliminarPartnerParent={eliminarPartner}></CardPartnerAdmin>);

    return (
        <form className="p-2" onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="" className="my-1"><strong>Aclaración</strong>: Las imágenes deben subirse en formato PNG con fondo transparente y deben tener una relación de aspecto de 2:1 aproximadamente para su correcta visualización.</label>
            <div className="container__documento d-flex align-items-center gap-3">
                <input className="form-control border border-primary h-25" type="file" id="formFile"
                    {...register("imagenSponsor")} accept="image/*" onChange={handleChange} />
                            {errors.imagenSponsor && <p className="text-danger">El archivo es requerido</p>}
                <button className="button__secondary my-2" type="submit">Guardar</button>
            </div>
            <div className="d-flex flex-wrap gap-2">
                {marcas ? renderImagenes(marcas) : ''}
                <img width="150px" height="80px" src={imagen || imgDefaultSponsor} alt="imagenSponsor" className="img__sponsor__new"/>
            </div>
            <div className="container my-5 d-flex justify-content-center">
                {/* <button id="button-guardar-head-coachs" className="button__primary" onClick={reset}><IoMdRefresh></IoMdRefresh></button> */}
            </div>
        </form>
    )
}