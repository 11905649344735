import Footer from '../../components/footer';
import Header from '../../components/header'
import HeaderSpace from '../../components/header-space'
import Documentos from './documentos'
import './style.css'

export default function DocumentosPage(){

    window.scrollTo(0, 0);
    
    return(
        <div className="div__home min-vh-100 h-10">
            <Header></Header>
            <HeaderSpace></HeaderSpace>
            <Documentos></Documentos>
            <Footer></Footer>
        </div>
    )
}