import { enviroment } from "../interfaces/enviroment";
import { Imagen } from "../interfaces/imagen";
import { MockBanners } from "../mocks/banners";
import { MockImagenes } from "../mocks/imagenes";

const BASE_URL: string = enviroment.urlBase() + "api/Seccion";
const ENV_DEMO: boolean = enviroment.demo();
let imagenes = localStorage.getItem("imagenes") ? JSON.parse(localStorage.getItem("imagenes")!) : undefined;

export const GetAllBanners = async (): Promise<Imagen[]> => {
    if (ENV_DEMO){
        if (imagenes === undefined){
            imagenes = MockBanners;
            localStorage.setItem("imagenes", JSON.stringify(imagenes));
        }
    }
    const url = `${BASE_URL}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: Imagen[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const GetAllBannersByType = async (seccionId?: number): Promise<Imagen[]> => {
    let banners = MockBanners;
    return banners.filter((x: any) => x.tipoImagen?.id == seccionId);    
}