import CardDeporte from "../../components/cardDeporte";
import { Imagen } from "../../interfaces/imagen";
import { MockDeportes } from "../../mocks/deportes";
import "./style.css"
import { useEffect, useState } from "react";

export default function HomeDeportes(){
    const [banners, setBanners] = useState<Imagen[]>();

    useEffect(()=>{
        fetchBanners();
    }, [])

    const fetchBanners = async () => {
        let bannersResponse = await MockDeportes;
        setBanners(bannersResponse);
    }

    const renderBanners = () => banners?.map((v, i) => <CardDeporte info={v} key={i}></CardDeporte>)

    return (
        <section className='row section__deportes w-100 p-5 mt-3'>
            {renderBanners()} 
        </section >
    )
}