import { useEffect, useState } from 'react';
import './style.css'

interface InputProps{
    data: any;
    updateCoach?: any;
}

export default function InputHeadCoach({data, updateCoach}: InputProps){
    const [empleado, setEmpleado] = useState<any>(data);
    const [empleadoId, setEmpeladoId] = useState<any>();

    useEffect(() => {
        setEmpleado(data);
        setEmpeladoId(`${data?.area?.descripcion?.replaceAll(" ", "")}_${data?.categoria?.descripcion?.replaceAll(" ", "")}_${data?.id}`)
    }, [])
    
    function changeNombre(e: any){
        let empleadoEditado = {...empleado, nombre: e.target.value}
        setEmpleado(empleadoEditado);
        updateCoach(empleadoEditado);
    }
        
    function changeTelefono(e: any){
        let empleadoEditado = {...empleado, telefono: e.target.value}
        setEmpleado(empleadoEditado);
        updateCoach(empleadoEditado)
    }
        
    function changeEmail(e: any){
        let empleadoEditado = {...empleado, correo: e.target.value}
        setEmpleado(empleadoEditado);
        updateCoach(empleadoEditado);
    }

    return (
        <div className="d-flex justify-content-between gap-3 mb-3 flex-wrap">
            <input type="text" placeholder="Nombre y Apellido" className="input__coach form-control rounded-0" id={`nombre_${empleadoId}`} value={empleado?.nombre} onChange={changeNombre}/>
            <input type="text" placeholder="Telefono" className="input__coach form-control rounded-0" id={`telefono_${empleadoId}`} value={empleado?.telefono} onChange={changeTelefono}/>
            <input type="text" placeholder="Correo" className="input__coach form-control rounded-0" id={`correo_${empleadoId}`} value={empleado?.correo} onChange={changeEmail}/>            
        </div>
    )

}