import "./style.css"
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { DeleteDocumento, GetAllDocumentos, InsertDocumento } from "../../services/documentoService";
import CardDocumentoAdmin from "../../components/cardDocumentoAdmin";
import { IoMdRefresh } from "react-icons/io";
import { Area } from "../../interfaces/area";
import { UploadImageCloudinary } from "../../services/cloudinaryService";
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmModal } from "../../utils/confirmModal";
import { useAuth } from "../../utils/authContext";

type FormDataClub = {
    name: string;
    pdfFile: FileList;
}
    
type FormDataRugby = {
    nameRugby: string;
    pdfFileRugby: FileList;
}
    
type FormDataHockey = {
    nameHockey: string;
    pdfFileHockey: FileList;
};

export default function AdminDocumentos() {
    const [documentos, setDocumentos] = useState<any>();
    const [documentosClub, setDocumentosClub] = useState<any>();
    const [documentosRugby, setDocumentosRugby] = useState<any>();
    const [documentosHockey, setDocumentosHockey] = useState<any>();
    const [categoriaRugby, setCategoriaRugby] = useState<any>();
    const [categoriaHockey, setCategoriaHockey] = useState<any>();
    const [token, setToken] = useState<string>('');
    const auth = useAuth();
    
    let urlDemo = "https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1"

    useEffect(() => {
        setToken(auth.token);
        fetchDocumentos();
    }, []);

    const fetchDocumentos = async () => {
        let documentosResponse = await GetAllDocumentos();
        setDocumentos(documentosResponse);

        const documentosAgrupados = groupBy(documentosResponse, (e: any) => e?.area?.descripcion);
        setDocumentosClub(documentosAgrupados?.Club);

        //console.log(documentosAgrupados)
        if (documentosAgrupados?.Rugby) {
            const documentosAgrupadosRugby = groupBy(documentosAgrupados?.Rugby, (e: any) => e?.categoria?.descripcion.replace(" ", "_"));
            setDocumentosRugby(documentosAgrupados?.Rugby);
        }

        if (documentosAgrupados?.Hockey) {
            const documentosAgrupadosHockey = groupBy(documentosAgrupados?.Hockey, (e: any) => e?.categoria?.descripcion.replace(" ", "_"));
            setDocumentosHockey(documentosAgrupados?.Hockey);
        }
    };

    const groupBy = (x: any, f: any) => x.reduce((a: any, b: any, i: any) => ((a[f(b, i, x)] ||= []).push(b), a), {});

    const { register: registerClub, handleSubmit: handleSubmitClub, formState: { errors: errorsClub } } = useForm<FormDataClub>();
    const { register: registerRugby, handleSubmit: handleSubmitRugby, formState: { errors: errorsRugby } } = useForm<FormDataRugby>();
    const { register: registerHockey, handleSubmit: handleSubmitHockey, formState: { errors: errorsHockey } } = useForm<FormDataHockey>();

    const onSubmit: SubmitHandler<FormDataClub> = data => {
        let tipo = { id: 1, descripcion: "Club"}
        guardarDocumento(data.name, data.pdfFile, tipo);
    };

    const onSubmitRugby: SubmitHandler<FormDataRugby> = data => {        
        let tipo = { id: 2, descripcion: "Rugby"}
        guardarDocumento(data.nameRugby, data.pdfFileRugby, tipo);
    };

    const onSubmitHockey: SubmitHandler<FormDataHockey> = data => {        
        let tipo = { id: 3, descripcion: "Hockey"}
        guardarDocumento(data.nameHockey, data.pdfFileHockey, tipo);
    };

    const changeCategoriaRugby = (e: any) => {
        const index = e.target.selectedIndex + 1;
        const value = e.target.value;
        setCategoriaRugby({id: index, descripcion: value})
    }
    
    const changeCategoriaHockey = (e: any) => {
        const index = e.target.selectedIndex + 1;
        const value = e.target.value;
        setCategoriaHockey({id: index, descripcion: value})
    }

    async function guardarDocumento(name: string, pdfFile: any, tipo: Area){
        const file = pdfFile[0];

        let response = await UploadImageCloudinary(file, "documentos");
        urlDemo = response;

        const blob = new Blob([file], { type: file.type });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
            const base64String = reader.result;
            const jsonData = JSON.stringify({ pdfFile: base64String });
            //console.log('Archivo en Base64:', base64String);
            //console.log('Datos JSON:', jsonData);
            
            let result;
            switch(tipo?.id){
                case 1:
                    result = await InsertDocumento({ id: undefined, nombre: name, url: urlDemo, area: tipo, idArea: tipo.id, idCategoria: undefined }, token);
                    setDocumentosClub([...documentosClub || [], { id: pdfFile[0].lastModified, nombre: name, url: urlDemo, area: tipo, idArea: tipo.id, idCategoria: undefined }]);
                    break;
                case 2:
                    result = await InsertDocumento({ id: undefined, nombre: name, url: urlDemo, area: tipo, categoria: categoriaRugby, idArea: tipo.id, idCategoria: categoriaRugby.id }, token);
                    setDocumentosRugby([...documentosRugby || [], { id: pdfFile[0].lastModified, nombre: name, url: urlDemo, area: tipo, categoria: categoriaRugby, idArea: tipo.id, idCategoria: categoriaRugby.id }]);
                    break;
                case 3:
                    result = await InsertDocumento({ id: undefined, nombre: name, url: urlDemo, area: tipo, categoria: categoriaHockey, idArea: tipo.id, idCategoria: categoriaHockey.id }, token);
                    setDocumentosHockey([...documentosHockey || [], { id: pdfFile[0].lastModified, nombre: name, url: urlDemo, area: tipo, categoria: categoriaHockey, idArea: tipo.id, idCategoria: categoriaHockey.id }]);
                    break;
            }

            if(result?.nombre != undefined){
                ConfirmModal("Conmfirmación", `Documento ${result?.nombre} subido correctamente`);
            }
        };
    }

    function eliminarDocumento(id: any){
        let documentoEliminado = documentos?.find((d: any) => d.id == id);
        confirmAlert({
            title: "Administración",
            message: `¿Desea eliminar a ${documentoEliminado?.nombre}?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                    },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: async () => 
                    {
                        let response = await DeleteDocumento(id, token);
                        ConfirmModal("Confirmación", "Se eliminó el documento correctamente")
                        setDocumentos(documentos?.filter((e: any) => e.id != id))
                    }
                }
            ]
        });
    }

    const renderDocumentos = (docs: any[]) => docs.map((v: any, i: any) => <CardDocumentoAdmin key={v.id || i} documento={v} eliminarDocumentoParent={eliminarDocumento}></CardDocumentoAdmin>);

    function reset() {
        confirmAlert({
            title: "Administración",
            message: `¿Desea restablecer los valores por defecto?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                    },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: () => 
                    {
                        localStorage.removeItem("documentos");
                        setTimeout(() => {                         
                            window.location.reload();          
                        }, 500);
                    }
                }
            ]
        }); 
    }

    return (
        <div className="documentos__container p-2">
            <div className="container mb-5 border-bottom">
                {documentosClub? <article className="d-flex justify-content-center flex-column">
                    <h4 className="title fw-bold mx-auto my-3 text-center">Club</h4>
                    <div className="d-flex justify-content-start align-items-start flex-column">
                        {documentosClub? renderDocumentos(documentosClub) : ''}
                    </div>
                </article> : ''}

                <form onSubmit={handleSubmitClub(onSubmit)}>
                    <label className="text-left mt-5">Agregar documento</label>
                    <div className="container__documento d-flex justify-content-between align-items-start gap-3 mb-3">
                        <div className="d-flex flex-column gap-1 w-100">
                            <input type="text" placeholder="Nombre" className="form-control rounded-0"
                                {...registerClub('name', { required: 'Este campo es requerido' })} id="nombreDocumento" />
                            {errorsClub.name && <p className="text-danger">{errorsClub.name.message}</p>}
                        </div>
                        <div className="d-flex flex-column gap-1 w-100">
                            <input className="form-control border border-primary" type="file" id="pdfFile"
                                {...registerClub('pdfFile', {
                                    required: 'Se requiere un archivo PDF',
                                    validate: (value) => value.length > 0 || 'El archivo es requerido',
                                })} accept="application/pdf" />
                            {errorsClub.pdfFile && <p className="text-danger">{errorsClub.pdfFile.message}</p>}
                        </div>
                        <button className="button__secondary" type="submit">Agregar</button>
                    </div>
                </form>
            </div>
            <div className="container my-5 border-bottom">

                <article className="d-flex justify-content-center flex-column">
                    <h4 className="title fw-bold mx-auto my-3 text-center">Rugby</h4>
                    <div className="d-flex justify-content-start align-items-start flex-column">
                        {documentosRugby ? renderDocumentos(documentosRugby) : ''}
                        {/* {documentosRugby?.Juveniles ? renderDocumentos(documentosRugby?.Juveniles) : ''}
                        {documentosRugby?.Infantiles ? renderDocumentos(documentosRugby?.Infantiles) : ''} */}
                    </div>
                </article>

                <form onSubmit={handleSubmitRugby(onSubmitRugby)}>
                    <label className="text-left mt-5">Agregar documento</label>
                    <div className="d-flex gap-2 mb-3 align-items-start">
                        <div className="d-flex flex-column gap-1  w-100">
                            <input type="text" placeholder="Nombre" className="form-control rounded-0"
                                {...registerRugby('nameRugby', { required: 'Este campo es requerido' })} id="nameRugby" />
                            {errorsRugby.nameRugby && <p className="text-danger">{errorsRugby.nameRugby.message}</p>}
                        </div>
                        <select id="categoria-rugby" className="form-select  w-100" onChange={changeCategoriaRugby}
                        >
                            <option key="1" value="Plantel superior">Plantel superior</option>
                            <option key="2" value="Juveniles">Juveniles</option>
                            <option key="3" value="Infantiles">Infantiles</option>
                        </select>
                    </div>

                    <div className="container__documento d-flex justify-content-between align-items-start gap-3 mb-3">
                        <div className="d-flex flex-column gap-1 w-100">
                            <input className="form-control border border-primary" type="file" id="formFile"
                                {...registerRugby('pdfFileRugby', {
                                    required: 'Se requiere un archivo PDF',
                                    validate: (value) => value.length > 0 || 'El archivo es requerido',
                                })} accept="application/pdf" />
                            {errorsRugby.pdfFileRugby && <p className="text-danger">{errorsRugby.pdfFileRugby.message}</p>}
                        </div>
                        <button className="button__secondary" type="submit">Agregar</button>
                    </div>
                </form>
            </div>
            <div className="container my-5 border-bottom">

                <article className="d-flex justify-content-center flex-column">
                    <h4 className="title fw-bold mx-auto my-3 text-center">Hockey</h4>
                    <div className="d-flex justify-content-start align-items-start flex-column">
                        {documentosHockey ? renderDocumentos(documentosHockey) : ''}
                        {/* {documentosHockey?.Juveniles ? renderDocumentos(documentosHockey?.Juveniles) : ''}
                        {documentosHockey?.Infantiles ? renderDocumentos(documentosHockey?.Infantiles) : ''} */}
                    </div>
                </article>

                <form onSubmit={handleSubmitHockey(onSubmitHockey)}>
                    <label className="text-left mt-5">Agregar documento</label>
                    <div className="d-flex gap-2 align-items-start mb-3">
                        <div className="d-flex flex-column gap-1 w-50">
                            <input type="text" placeholder="Nombre" className="form-control rounded-0"
                                {...registerHockey('nameHockey', { required: 'Este campo es requerido' })} id="nameHockey" />
                            {errorsHockey.nameHockey && <p className="text-danger">{errorsHockey.nameHockey.message}</p>}
                        </div>
                        <select id="categoria-hockey" className="form-select  w-100" onChange={changeCategoriaHockey}
                        >
                            <option key="1" value="Plantel superior">Plantel superior</option>
                            <option key="2" value="Juveniles">Juveniles</option>
                            <option key="3" value="Infantiles">Infantiles</option>
                        </select>
                    </div>

                    <div className="container__documento d-flex justify-content-between align-items-start gap-3 mb-3">
                        <div className="d-flex flex-column gap-1 w-100">
                            <input className="form-control border border-primary" type="file" id="formFile"
                                {...registerHockey('pdfFileHockey', {
                                    required: 'Se requiere un archivo PDF',
                                    validate: (value) => value.length > 0 || 'El archivo es requerido',
                                })} accept="application/pdf" />
                            {errorsHockey.pdfFileHockey && <p className="text-danger">{errorsHockey.pdfFileHockey.message}</p>}
                        </div>
                        <button className="button__secondary" type="submit">Agregar</button>
                    </div>
                </form>
            </div>
            <div className="container my-5 d-flex justify-content-center">
                {/* <button id="button-guardar-head-coachs" className="button__primary" onClick={reset}><IoMdRefresh></IoMdRefresh></button> */}
            </div>
        </div>
    )
}