import './style.css'
import { useForm } from 'react-hook-form';
import { Usuario } from '../../interfaces/usuario';

interface UsuarioProps {
    data?: Usuario,
    login: any
}

export default function LoginForm({ data, login }: UsuarioProps) {
    const { register, formState: { errors }, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            user: data?.email || '',
            password: data?.password || ''
        }
    });

    const ingresar = async (formData: any) => {
        //console.log(formData)
        await login(formData);
    }

    return (
        <div className="container-fluid mw-50 pt-3 h-100">
            <div className="container-fluid w-50 border border-secondary rounded-4 div__title">
                <div>
                    <h1 className="title text-info p-2 mb-0 text-center">Login</h1>
                    <h3 className="title text-danger p-2 text-center">Iniciar sesión</h3>
                </div>

                <div className="container text-light">
                    <form onSubmit={handleSubmit(ingresar)}>
                        <div className="container my-4">
                            <label className="text-left">Usuario</label>
                            <input type="text" placeholder="Usuario..." className="form-control rounded-0"
                                {...register("user", { required: true })} id="user" />
                            {errors.user?.type === 'required' && <span className="text-danger"> El campo usuario es requerido</span>}
                        </div>
                        <div className="container my-3">
                            <label className="text-left">Contraseña</label>
                            <input type="password" placeholder="Contraseña..." className="form-control rounded-0"
                                {...register("password", { required: true })} id="password" />
                            {errors.password?.type === 'required' && <span className="text-danger"> El campo contraseña es requerido</span>}
                        </div>
                        <div className="container text-light w-100 d-flex align-items-center justify-content-center my-3 p-1">
                            <button type="submit" id="btnLoginSubmit" className="button__primary">Ingresar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}