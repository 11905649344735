import "./style.css";
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";
import OwlCarousel from "react-owl-carousel";

interface InfoProps {
    info?: Imagen[];
}

export default function CardBanner({ info }: InfoProps) {
    const [fixtures, setFixtures] = useState<Imagen[]>();

    useEffect(() => {
        setFixtures(info);
    }, []);

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: info?.length != undefined && info?.length > 1,
        dots: false,
        autoplay: false,
        navContainerClass: `owl-nav d-flex justify-content-around`,
        navClass: ['border-0 rounded-2 px-2 fs-5 position-absolute top-50 start-0 ms-2 nav__carousel', 
                    'border-0 rounded-2 px-2 fs-5 position-absolute top-50 end-0 me-2 nav__carousel'],
        controlsClass: 'owl-controls',
        smartSpeed: 1000,
        autoplayTimeout: 3000,
        responsive: {
            0: {
                items: 1
            }
        }
    };

    const renderFixtures = () => fixtures?.map((v, i) => <img src={v?.url} alt={v?.titulo} key={i} className="fixture__img"></img>)

    return (
        <article className="fixture__container col-12 col-md-6 d-flex mb-2 justify-content-center">
            {fixtures ? <OwlCarousel className='fixture__carousel position-relative' loop {...options}>
                {renderFixtures()}
            </OwlCarousel> : ''}
        </article>
    )
}