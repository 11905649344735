import './style.css'
import { useForm } from 'react-hook-form';
import { Usuario } from '../../interfaces/usuario';
import Header from '../../components/header';
import HeaderSpace from '../../components/header-space';


export default function LoginPage() {
    
    return (
        <>
            <Header></Header>
            <HeaderSpace></HeaderSpace>
        </>
    );
}