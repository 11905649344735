import { Imagen } from "../interfaces/imagen";
import imgRugby from "../media/img/fixtures/fixture_urba.webp";
import imgHockey1 from "../media/img/fixtures/fixture_tira_a.webp";
import imgHockey2 from "../media/img/fixtures/fixture_tira_a.webp";
import _901box from '../media/img/sponsor/901_box.png';
import aconcagua from '../media/img/sponsor/aconcagua.png';
import anclaflex from '../media/img/sponsor/anclaflex.png';
import be_club from '../media/img/sponsor/be_club.png';
import boloqui from '../media/img/sponsor/boloqui.png';
import brucke from '../media/img/sponsor/brucke.png';
import eco_de_los_andes from '../media/img/sponsor/eco_de_los_andes.png';
import ersa from '../media/img/sponsor/ersa.png';
import estudio_girbert from '../media/img/sponsor/estudio_girbert.png';
import gatorade from '../media/img/sponsor/gatorade.png';
import good_fit from '../media/img/sponsor/good_fit.png';
import hielo_city from '../media/img/sponsor/hielo_city.png';
import imperio from '../media/img/sponsor/imperio.png';
import leal from '../media/img/sponsor/leal.png';
import macro from '../media/img/sponsor/macro.png';
import michelob from '../media/img/sponsor/michelob.png';
import pim_pollo from '../media/img/sponsor/pim_pollo.png';
import procer from '../media/img/sponsor/procer.png';
import sygsa from '../media/img/sponsor/sygsa.png';
import _0221 from '../media/img/sponsor/_0221.png';
import bannerHome from "../media/img/banner/banner_home.webp";
import bannerClub from "../media/img/banner/banner_club.webp";
import bannerRugby from "../media/img/banner/banner_rugby.webp";
import bannerHockey from "../media/img/banner/banner_hockey.webp";

export const MockImagenes: Imagen[] =
    [
        
        {
            id: 1,
            titulo: 'CLUB SAN LUIS',
            subtitulo: '',
            descripcion: '',
            url: bannerHome,
            tipoImagen: {
                id: 1,
                descripcion: 'Banner'
            },
            idTipoImagen: 1
        },
        {
            id: 2,
            titulo: 'CLUB',
            subtitulo: '',
            descripcion: '',
            url: bannerClub,
            tipoImagen: {
                id: 1,
                descripcion: 'Banner'
            },
            idTipoImagen: 1
        },
        {
            id: 3,
            titulo: 'RUGBY',
            subtitulo: '',
            descripcion: '',
            url: bannerRugby,
            tipoImagen: {
                id: 1,
                descripcion: 'Banner'
            },
            idTipoImagen: 1
        },
        {
            id: 4,
            titulo: 'HOCKEY',
            subtitulo: '',
            descripcion: '',
            url: bannerHockey,
            tipoImagen: {
                id: 1,
                descripcion: 'Banner'
            },
            idTipoImagen: 1
        },        
        
        { id: 5, descripcion: 'boloqui', url: boloqui, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 6, descripcion: 'brucke', url: brucke, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 7, descripcion: 'eco_de_los_andes', url: eco_de_los_andes, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 8, descripcion: 'ersa', url: ersa, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 9, descripcion: 'estudio_girbert', url: estudio_girbert, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 10, descripcion: 'gatorade', url: gatorade, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 11, descripcion: 'good_fit', url: good_fit, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 12, descripcion: 'hielo_city', url: hielo_city, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 13, descripcion: 'imperio', url: imperio, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 14, descripcion: 'leal', url: leal, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 16, descripcion: 'macro', url: macro, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 17, descripcion: 'michelob', url: michelob, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 18, descripcion: 'pim_pollo', url: pim_pollo, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 19, descripcion: 'procer', url: procer, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 20, descripcion: 'sygsa', url: sygsa, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 21, descripcion: '_0221', url: _0221, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 22, descripcion: '_901box', url: _901box, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 23, descripcion: 'aconcagua', url: aconcagua, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 24, descripcion: 'anclaflex', url: anclaflex, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },
        { id: 25, descripcion: 'be_club', url: be_club, tipoImagen: { id: 4, descripcion: 'Sponsor' }, idTipoImagen: 4 },

        {
            id: 26,
            titulo: 'RUGBY',
            subtitulo: '',
            descripcion: '',
            url: imgRugby,
            tipoImagen: {
                id: 2,
                descripcion: 'Fixture Rugby'
            }, 
            idTipoImagen: 2
        },
        {
            id: 27,
            titulo: 'HOCKEY 1',
            subtitulo: '',
            descripcion: '',
            url: imgHockey1,
            tipoImagen: {
                id: 3,
                descripcion: 'Fixture Hockey'
            }, 
            idTipoImagen: 3
        },
        {
            id: 28,
            titulo: 'HOCKEY 2',
            subtitulo: '',
            descripcion: '',
            url: imgHockey2,
            tipoImagen: {
                id: 3,
                descripcion: 'Fixture Hockey'
            }, 
            idTipoImagen: 3
        },
    ]