import "./style.css"
import { useEffect, useState } from "react"
import { MockEmpleados } from "../../mocks/empleados";
import { GetAllEmpleados } from "../../services/empleadoService";

export default function ClubComision() {
    const [comision, setComision] = useState<any>();

    useEffect(() => {
        fetchComision();
    }, [])

    const fetchComision = async () => {
        let comisionResponse = await GetAllEmpleados();
        const empleados = groupBy(comisionResponse, (e: any) => e?.tipoEmpleado?.descripcion.replaceAll(" ", "_"))
        setComision(empleados);
    }

    const groupBy = (x: any, f: any) => x.reduce((a: any, b: any, i: any) => ((a[f(b, i, x)] ||= []).push(b), a), {});

    function groupEmpleados(empleados: any) {
        const resultado = empleados.reduce((group: any, entity: any) => {
            group[entity?.tipoEmpleado?.id] = group[entity?.tipoEmpleado?.id] ?? [];
            group[entity?.tipoEmpleado?.id].push(entity);
            return group;
        }, []);
        return resultado;
    }

    return (
        <section className="section__comision">
            <h3 className="title text-center fw-bold mb-3">Comisión directiva</h3>
            <h6 className="text-center">2023-2025</h6>
            <div className="d-flex justify-content-center ps-4">
                <article className="comision__container flex-column my-4">
                    {comision?.Presidente ?
                        <div className="d-flex text-start align-items-start">
                            <label className="text-secondary me-2 mb-0 fw-bold">Presidente: </label>
                            <p className="mb-0">{comision.Presidente[0].nombre}</p>
                        </div> : ''}

                    {comision?.Vicepresidente ?
                        <div className="d-flex text-start align-items-start">
                            <label className="text-secondary me-2 mb-0 fw-bold">Vicepresidente: </label>
                            <p className="mb-0">{comision.Vicepresidente[0].nombre}</p>
                        </div> : ''}

                    {comision?.Secretario ?
                        <div className="d-flex text-start align-items-start">
                            <label className="text-secondary me-2 mb-0 fw-bold">Secretario: </label>
                            <p className="mb-0">{comision.Secretario[0].nombre}</p>
                        </div> : ''}

                    {comision?.Tesorero ?
                        <div className="d-flex text-start align-items-start">
                            <label className="text-secondary me-2 mb-0 fw-bold">Tesorero: </label>
                            <p className="mb-0">{comision.Tesorero[0].nombre}</p>
                        </div> : ''}

                    {comision?.Pro_Secretario ?
                        <div className="d-flex text-start align-items-start">
                            <label className="text-secondary me-2 mb-0 fw-bold">Pro Secretario: </label>
                            <p className="mb-0">{comision.Pro_Secretario[0].nombre}</p>
                        </div> : ''}

                    {comision?.Pro_Tesorero ?
                        <div className="d-flex text-start align-items-start">
                            <label className="text-secondary me-2 mb-0 fw-bold">Pro Tesorero: </label>
                            <p className="mb-0">{comision.Pro_Tesorero[0].nombre}</p>
                        </div> : ''}
                </article>
            </div>

            <div className="row vocales__container m-auto">
                {comision?.Vocales_titulares ?
                    <div className="col-12 col-md-4 d-flex text-start flex-column align-items-center py-3 vocales__column">
                        <div>
                            <label className="text-secondary me-2 mb-0 fw-bold">Vocales titulares: </label>
                            {comision.Vocales_titulares.map((v: any, i: any) => <p key={i} className="mb-0">{v.nombre}</p>)}
                        </div>
                    </div> : ''}

                {comision?.Vocales_suplentes ?
                    <div className="col-12 col-md-4 d-flex text-start flex-column align-items-center py-3 vocales__column">
                        <div>
                            <label className="text-secondary me-2 mb-0 fw-bold">Vocales suplentes: </label>
                            {comision.Vocales_suplentes.map((v: any, i: any) => <p key={i} className="mb-0">{v.nombre}</p>)}
                        </div>
                    </div> : ''}

                {comision?.Revisores_de_cuentas?.length ?
                    <div className="col-12 col-md-4 d-flex text-start flex-column align-items-center py-3 vocales__column">
                        <div>
                            <label className="text-secondary me-2 mb-0 fw-bold">Revisores de cuentas: </label>
                            {comision.Revisores_de_cuentas.map((v: any, i: any) => <p key={i} className="mb-0">{v.nombre}</p>)}
                        </div>
                    </div> : ''}
            </div>


        </section>
    )
}