import Footer from '../../components/footer'
import Header from '../../components/header'
import TopBanner from '../../components/top-banner'
import HockeyPlanteles from './planteles'
import HockeySectionText from './section-text'
import './style.css'

export default function HockeyPage(){

    window.scrollTo(0, 0);
    
    return(
        <div className="div__home min-vh-100 h-10">
            <Header></Header>
            <TopBanner id={4}></TopBanner>
            <HockeySectionText></HockeySectionText>
            <HockeyPlanteles></HockeyPlanteles>
            <Footer></Footer>
        </div>
    )
}