import { useEffect, useState } from 'react';
import './style.css'
import { useForm } from 'react-hook-form';
import { Empleado } from '../../interfaces/empleado';
import { FaCheck, FaRegTrashAlt } from "react-icons/fa";

interface InputProps{
    data: Empleado;
    eliminarEmpleadoChild: any;
    actualizarEmpleadoChild: any;
}

export default function InputEmpleadoMultiple({data, eliminarEmpleadoChild, actualizarEmpleadoChild}: InputProps){
    const [empleado, setEmpleado] = useState<Empleado>(data);
    const [empleadoId, setEmpeladoId] = useState<any>();

    useEffect(() => {        
        setEmpleado(data);
        setEmpeladoId(`${data?.tipoEmpleado?.descripcion?.replaceAll(" ", "")}_${data?.id}`)
    }, [])
    
    function changeEmpleado(e: any){
        setEmpleado({...empleado, nombre: e.target.value})
        //actualizarEmpleadoChild({...empleado, nombre: e.target.value})
    }

    function eliminarEmpleado(){
        eliminarEmpleadoChild(empleado?.id);
    }

    function guardarEmpleado(){
        //console.log(empleado);
        if(empleado?.nombre != ""){
            actualizarEmpleadoChild(empleado)
        }
        else{
            eliminarEmpleadoChild(empleado?.id);
        }
    }

    return (
        <div className="input-group mb-3">
            <input type="text" placeholder="Nombre y Apellido" className={`form-control rounded-0 ${empleado?.id == 0? 'input__new': ''}`} id={empleadoId} value={empleado?.nombre} onChange={changeEmpleado}/>
            <div className="input-group-append">
                {empleado?.id! > 0? <button className="btn btn-outline-danger rounded-0" type="button" onClick={eliminarEmpleado}><FaRegTrashAlt></FaRegTrashAlt></button>: 
                ''}
                <button className="btn btn-outline-success rounded-0 button__guardar__comision" type="button" onClick={guardarEmpleado}><FaCheck></FaCheck></button>
            </div>
        </div>
    )

}