import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { AdminRoute, ClubRoute, ContactoRoute, DocumentosRoute, HockeyRoute, InicioRoute, LoginRoute, RugbyRoute } from "./routes";
import { ProtectedRoute } from "./utils/protectedRoute";
import { useState } from "react";
import { Usuario } from "./interfaces/usuario";
import AuthProvider from "./utils/authContext";

export default function Router() {
    const [user, setUser] = useState<Usuario>();

    let usuario: Usuario = { id: 1, nombre: "Maxi", email: "Administrador", habilitado: true }

    const login = () => {
        setUser(usuario)
    }

    const logout = () => setUser(undefined);

    return (
        <HashRouter>
            <AuthProvider>
            <Routes>
                <Route index element={<InicioRoute></InicioRoute>}></Route>
                <Route path="/" element={<InicioRoute></InicioRoute>}></Route>
                <Route path="/inicio" element={<InicioRoute></InicioRoute>}></Route>
                <Route path="/login" element={<LoginRoute></LoginRoute>}></Route>
                <Route path="/contacto" element={<ContactoRoute></ContactoRoute>}></Route>
                <Route path="/club" element={<ClubRoute></ClubRoute>}></Route>
                <Route path="/documentos" element={<DocumentosRoute></DocumentosRoute>}></Route>
                <Route path="/hockey" element={<HockeyRoute></HockeyRoute>}></Route>
                <Route path="/rugby" element={<RugbyRoute></RugbyRoute>}></Route>

                <Route element={<ProtectedRoute isAllowed={!!usuario && usuario.email == "Administrador"} />}>
                    <Route path="/admin" element={<AdminRoute></AdminRoute>}></Route>
                </Route>
            </Routes>
            </AuthProvider>
        </HashRouter>
    )
}