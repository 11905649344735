import "./style.css";
import { useEffect, useState } from "react";
import CardPartner from "../../components/cardPartner";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Imagen } from "../../interfaces/imagen";
import { GetAllImagesByType } from "../../services/imagenService";

export default function HomeMarcas(){
    const [partners, setPartners] = useState<Imagen[]>();

    useEffect(() => {
        fetchPartners();
    }, []);

    const fetchPartners = async () => {
        let lista: Imagen[] = await GetAllImagesByType(4);
        setPartners(lista);
    }

    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        navContainerClass: `owl-nav`,        
        navClass: ['owl-prev','owl-next'],
        controlsClass: 'owl-controls',
        smartSpeed: 1000,     
        autoplayTimeout: 3000,
        responsive:{
            0:{
                items:2
            },
            600:{
                items:3
            },
            900:{
                items:3
            },
            1000:{
                items:5
            }
        }
    };

    const renderPartners = () => partners?.map((v, i) => <CardPartner data={v} key={i}></CardPartner>)

    return (
        <section className="py-5">
                <div className="sectionTitle">
                {partners && partners.length>0? <h3 className="text-center mb-4 fw-bold">Nuestros amigos</h3>: ''}
                </div>
                <div className="carousel__container">  
                    <div className="container py-2">
                        {partners && partners.length>0? <OwlCarousel className='owl-theme' loop {...options}>
                            {renderPartners()}
                        </OwlCarousel>: ''}
                    </div>                  
                </div>
        </section>
    )
}