import "./style.css"
import { Documento } from "../../interfaces/documento"
import { useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";

interface DocumentoProps {
    documento: Documento;
    eliminarDocumentoParent: any;
}

export default function CardDocumentoAdmin({ documento, eliminarDocumentoParent }: DocumentoProps) {
    const [archivo, setArchivo] = useState<Documento>(documento);

    const viewBrochure = async () => {
        const url = archivo?.url!;
        let alink = document.createElement("a");
        alink.href = url;
        alink.target = "_blank";
        alink.rel = "noreferrer"
        alink.click();
    }

    function eliminarDocumento(e: any) {
        //console.log(archivo?.id)
        eliminarDocumentoParent(archivo?.id)
    }

    return (
        <li className="documento__admin__container my-1 border border-1 w-100 ps-1 d-flex justify-content-between">
            <div className="d-flex">
                <a className="documento__link my-0 me-2" onClick={viewBrochure}>{archivo?.nombre}</a>
            </div>
            <div className="d-flex">
                {archivo?.categoria?.descripcion ? <p className="me-2 my-0 w-100">({archivo?.categoria?.descripcion})</p> : ''}
                {/* {archivo?.area?.descripcion? <p className="fw-bold me-2 my-0">{archivo?.area?.descripcion}</p>: '' } */}
            </div>
            <button className="btn btn-outline-danger rounded-0" type="button" onClick={eliminarDocumento}><FaRegTrashAlt></FaRegTrashAlt></button>
        </li>
    )
}