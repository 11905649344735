import "./style.css"
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";
import CardFixture from "../../components/cardFixture";
import { GetAllImagesByType } from "../../services/imagenService";

export default function HomeFixtures(){
    const [fixtures, setFixtures] = useState<Imagen[]>();
    const [fixturesRugby, setFixturesRugby] = useState<Imagen[]>();
    const [fixturesHockey, setFixturesHockey] = useState<Imagen[]>();

    useEffect(()=>{
        fetchFixtures();
    }, [])

    const fetchFixtures = async () => {
        let fixturesResponseRugby = await GetAllImagesByType(2);
        let fixturesResponseHockey = await GetAllImagesByType(3);
        setFixturesRugby(fixturesResponseRugby)
        setFixturesHockey(fixturesResponseHockey)
    }

    return (
        <section className='row section__deportes w-100 p-5 mt-3'>
            {fixturesRugby && fixturesRugby?.length> 0 && fixturesHockey && fixturesHockey?.length> 0 ? <h3 className="text-center mb-5 fw-bold">Fixtures</h3>: ''}
            {fixturesRugby && fixturesRugby?.length> 0? <CardFixture info={fixturesRugby}></CardFixture>: ''}
            {fixturesHockey && fixturesHockey?.length> 0? <CardFixture info={fixturesHockey}></CardFixture>: ''}
        </section >
    )
}