import { createContext, useContext, useState } from "react";
import { Usuario } from "../../interfaces/usuario";
import { useNavigate } from "react-router-dom";
import { AutenticarUsuario } from "../../services/usuarioService";

interface ProviderProps {
    user:  string | undefined,
    token:  string,
    login (data: Usuario ): void,
    logout() :void,
}

const AuthContext = createContext<ProviderProps>({
    user: undefined,
    token: '',
    login: () => {},
    logout: () => {}
})

const AuthProvider = ({ children }: { children: React.ReactNode}) => {
    const storedInfo =  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null
    const [user, setUser ] = useState<string | undefined>(storedInfo?.email)
    const [ token, setToken ] = useState( storedInfo?.token || '')
    const naigate = useNavigate()

    const login = async (data:Usuario ) => {
        let result = await AutenticarUsuario(data);    
        if (result){
            //localStorage.setItem("token", JSON.stringify(result?.replace(/['"]+/g, '')));
            const obj = { token: result }
            setUser(data?.user)
            setToken(result)
            localStorage.setItem('user',JSON.stringify(obj));
            setTimeout(() => {
                window.location.reload();
            }, 1000);
            //naigate('/')
        }
    }

    const logout = () => {
        setUser(undefined)
        setToken('')
        localStorage.removeItem('user')
    }

    return (
        <AuthContext.Provider value={{ user,token, login, logout}}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthProvider

export const useAuth = () => {
    return useContext(AuthContext)
}