import { Documento } from "../interfaces/documento";
import documento1 from "../media/docs/ESTATUTO CSL..pdf"
import documento2 from "../media/docs/PAUTAS DE CONVIVENCIA Y CONDUCTA..pdf"
import documento3 from "../media/docs/REGLAMENTO DE GIRAS DEL CSL.pdf"

export const MockDocumentos: Documento[] = 
[
    {
        id: 0,
        nombre: 'Estatuto y reglamento.',
        url: documento1,
        orden: 1,
        area: {
            id: 1,
            descripcion: 'Club'
        },
        idArea: 1
    },
    {
        id: 1,
        nombre: 'Pautas de convivencia y conducta.',
        url: documento2,
        orden: 2,
        area: {
            id: 1,
            descripcion: 'Club'
        },
        idArea: 1
    },
    {
        id: 2,
        nombre: 'Reglamento de giras.',
        url: documento3,
        orden: 1,
        area: {
            id: 1,
            descripcion: 'Club'
        },
        categoria: {
            id: 1,
            descripcion: 'Plantel superior'
        },
        idArea: 1
    },
    {
        id: 3,
        nombre: 'Inscripción UAR.',
        url: documento3,
        orden: 1,
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 1,
            descripcion: 'Plantel superior'
        },
        idArea: 1
    },
    {
        id: 4,
        nombre: 'Formulario Apto Médico.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 2,
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 1,
            descripcion: 'Plantel superior'
        },
        idArea: 2,
        idCategoria: 1
    },
    {
        id: 5,
        nombre: 'Inscripción UAR.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 1,
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 2,
            descripcion: 'Juveniles'
        },
        idArea: 2,
        idCategoria: 2
    },
    {
        id: 6,
        nombre: 'Formulario Apto Médico.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 2,
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 2,
            descripcion: 'Juveniles'
        },
        idArea: 2,
        idCategoria: 2
    },
    {
        id: 7,
        nombre: 'Inscripción UAR.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 1,
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 3,
            descripcion: 'Infantiles'
        },
        idArea: 2,
        idCategoria: 3
    },
    {
        id: 8,
        nombre: 'Formulario Apto Médico.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 2,
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 3,
            descripcion: 'Infantiles'
        },
        idArea: 2,
        idCategoria: 3
    },
    {
        id: 9,
        nombre: 'Inscripción AHBA.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 1,
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 1,
            descripcion: 'Plantel superior'
        },
        idArea: 3,
        idCategoria: 1
    },
    {
        id: 10,
        nombre: 'Formulario Apto Médico.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 2,
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 1,
            descripcion: 'Plantel superior'
        },
        idArea: 3,
        idCategoria: 1
    },
    {
        id: 11,
        nombre: 'Inscripción AHBA.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 1,
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 2,
            descripcion: 'Juveniles'
        },
        idArea: 3,
        idCategoria: 2
    },
    {
        id: 12,
        nombre: 'Formulario Apto Médico.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 2,
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 2,
            descripcion: 'Juveniles'
        },
        idArea: 3,
        idCategoria: 2
    },
    {
        id: 13,
        nombre: 'Inscripción AHBA.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 1,
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 3,
            descripcion: 'Infantiles'
        },
        idArea: 3,
        idCategoria: 3
    },
    {
        id: 14,
        nombre: 'Formulario Apto Médico.',
        url: 'https://firebasestorage.googleapis.com/v0/b/digitalproject-demo.appspot.com/o/DEMO.pdf?alt=media&token=b8f75382-2891-40dd-bff1-7c1ed64ddbb1',
        orden: 2,
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 3,
            descripcion: 'Infantiles'
        },
        idArea: 3,
        idCategoria: 3
    }
]