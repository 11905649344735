import "./style.css"
import AdminCoachs from "./admin-coachs";
import AdminDocumentos from "./admin-documentos";
import AdminFixtures from "./admin-fixtures";
import AdminSponsors from "./admin-sponsors";
import AdminComision from "./admin-comision";

export default function FormAdministracion() {    

    return (
        <div className="accordion px-1">
            <article className="px-1 my-">
                <div className="container accordion-item p-0">
                    <h3 className="accordion-header" id="headingOne">
                        <button className="accordion-button px-3 py-0 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <p className="my-4">Comisión Directiva</p>
                        </button>
                    </h3>
                    <div id="collapseOne" className="accordion-collapse collapse px-1" aria-labelledby="headingOne">
                        <AdminComision></AdminComision>
                    </div>
                </div>
            </article>

            <article className="px-1 my-3">
                <div className="container accordion-item p-0">
                    <h3 className="accordion-header" id="headingTwo">
                        <button className="accordion-button px-3 py-0 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                            <p className="my-4">Head Coachs</p>
                        </button>
                    </h3>

                    <div id="collapseTwo" className="accordion-collapse collapse px-1" aria-labelledby="headingTwo">
                        <AdminCoachs></AdminCoachs>
                    </div>
                </div>
            </article>

            <article className="px-1 my-3">
                <div className="container accordion-item p-0">
                    <h3 className="accordion-header" id="headingThree">
                        <button className="accordion-button px-3 py-0 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                            <p className="my-4">Documentos</p>
                        </button>
                    </h3>

                    <div id="collapseThree" className="accordion-collapse collapse px-1" aria-labelledby="headingThree">
                        <AdminDocumentos></AdminDocumentos>
                    </div>
                </div>
            </article>

            <article className="px-1 my-3">
                <div className="container accordion-item p-0">
                    <h3 className="accordion-header" id="headingFour">
                        <button className="accordion-button px-3 py-0 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                            <p className="my-4">Fixtures</p>
                        </button>
                    </h3>

                    <div id="collapseFour" className="accordion-collapse collapse px-1" aria-labelledby="headingFour">
                        <AdminFixtures></AdminFixtures>
                    </div>
                </div>
            </article>

            <article className="px-1 my-3">
                <div className="container accordion-item p-0">
                    <h3 className="accordion-header" id="headingFive">
                        <button className="accordion-button px-3 py-0 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            <p className="my-4">Sponsors</p>
                        </button>
                    </h3>

                    <div id="collapseFive" className="accordion-collapse collapse px-1" aria-labelledby="headingFive">
                        <AdminSponsors></AdminSponsors>
                    </div>
                </div>
            </article>
        </div>
    )
}