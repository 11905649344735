import { enviroment } from "../interfaces/enviroment";
import { Imagen } from "../interfaces/imagen";
import { MockImagenes } from "../mocks/imagenes";
import { UnauthorizedLogout } from "../utils/unauthorizedLogout";

const BASE_URL: string = enviroment.urlBase() + "api/Imagen";
const ENV_DEMO: boolean = enviroment.demo();
let imagenes = localStorage.getItem("imagenes") ? JSON.parse(localStorage.getItem("imagenes")!) : undefined;

export const GetAllImages = async (): Promise<Imagen[]> => {
    if (ENV_DEMO){
        if (imagenes === undefined){
            imagenes = MockImagenes;
            localStorage.setItem("imagenes", JSON.stringify(imagenes));
        }
    }
    const url = `${BASE_URL}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: Imagen[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const GetAllImagesByType = async (tipoId?: number): Promise<Imagen[]> => {
    if (ENV_DEMO){
        if (imagenes === undefined){
            imagenes = MockImagenes;
            localStorage.setItem("imagenes", JSON.stringify(imagenes));
        }

        return imagenes.filter((x: any) => x.tipoImagen?.id == tipoId);
    }
    const url = `${BASE_URL}/TipoId?id=${tipoId}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: Imagen[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const InsertImage= async (data: any, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        localStorage.setItem("imagenes", JSON.stringify([...imagenes, data]));
        return 0;
    }
    
    const url = `${BASE_URL}`;
    let result;
    try {
        data.id = undefined;
        const response = await fetch(url, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        result = await response.json();
        if(response.ok && response.status == 201){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const UpdateImage = async (data: any, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }
    
    const url = `${BASE_URL}`;
    let result;
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);
        }
        result = await response.json();
        if(response.ok && response.status == 200){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const DeleteImage = async (id: number, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        imagenes = imagenes.filter((x: any) => x.id != id);
        localStorage.setItem("imagenes", JSON.stringify(imagenes));  
        return "Imagen eliminada exitosamente";
    }

    const url = `${BASE_URL}/${id}`;
    let result;
    try {
        const response = await fetch(url, {
            method: "DELETE",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(id) 
        })        
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }   
        
        if(response.ok){
            return response;
        }     
    } catch (error) {
        return undefined;
    }    
}