import "./style.css"
import { useEffect, useState } from "react";
import InputHeadCoach from "../../components/inputHeadCoach"
import { HeadCoach } from "../../interfaces/headCoach";
import { GetAllHeadCoachs, UpdateHeadCoach } from "../../services/headCoachService";
import { IoMdRefresh } from "react-icons/io";
import { confirmAlert } from 'react-confirm-alert';
import { useAuth } from "../../utils/authContext";
import { ConfirmModal } from "../../utils/confirmModal";

export default function AdminCoachs(){
    const [headCoachs, setHeadCoachs] = useState<HeadCoach[] | undefined>();
    const [token, setToken] = useState<string>('');
    const auth = useAuth();

    useEffect(() => {
        setToken(auth.token);
        fetchHeadCoachs();
    }, [])
    
    const fetchHeadCoachs = async () => {
        let coaches = await GetAllHeadCoachs();
        setHeadCoachs(coaches);
    }

    function guardarCoachs(){
        try{
            headCoachs?.forEach(element => {
                UpdateHeadCoach(element, token);            
            });
            ConfirmModal("Confirmación", "Head Coachs actualizados correctamente");
        }
        catch(error: any){
            ConfirmModal("Error", "Se produjo un error al actualizar los head coachs");
        }        
        localStorage.setItem("headCoachs", JSON.stringify(headCoachs));
    }

    function updateCoach(e: any){
        setHeadCoachs(headCoachs?.map(x => x.id === e.id ? {...x , nombre: e.nombre, telefono: e.telefono, correo: e.correo}: x));        
    }

    function reset(){
        confirmAlert({
            title: "Administración",
            message: `¿Desea restablecer los valores por defecto?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: () => 
                    {
                        localStorage.removeItem("headCoachs");
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                }
            ]
        });        
    }

    return(
        <div className="coachs__container">
            <div className="container my-3">
                <h5 className="title my-4 text-center fw-bold">Rugby</h5>
                <label className="text-left">Plantel Superior</label>
                {headCoachs?<InputHeadCoach data={headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 1)} updateCoach={updateCoach}></InputHeadCoach>: ''}
            </div>
            <div className="container my-3">
                <label className="text-left">Juveniles</label>
                {headCoachs?<InputHeadCoach data={headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 2)} updateCoach={updateCoach}></InputHeadCoach>: ''}
            </div>
            <div className="container my-3">
                <label className="text-left">Infantiles</label>
                {headCoachs?<InputHeadCoach data={headCoachs?.find(x => x.area?.id == 2 && x.categoria?.id == 3)} updateCoach={updateCoach}></InputHeadCoach>: ''}
            </div>

            <br />
            <div className="container my-3">
                <h5 className="title my-4 text-center fw-bold">Hockey</h5>
                <label className="text-left">Plantel Superior</label>
                {headCoachs?<InputHeadCoach data={headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 1)} updateCoach={updateCoach}></InputHeadCoach>: ''}
            </div>
            <div className="container my-3">
                <label className="text-left">Juveniles</label>
                {headCoachs?<InputHeadCoach data={headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 2)} updateCoach={updateCoach}></InputHeadCoach>: ''}
            </div>
            <div className="container my-3">
                <label className="text-left">Infantiles</label>
                {headCoachs?<InputHeadCoach data={headCoachs?.find(x => x.area?.id == 3 && x.categoria?.id == 3)} updateCoach={updateCoach}></InputHeadCoach>: ''}
            </div>
            <div className="container text-light w-100 d-flex align-items-center justify-content-center">
                {/* <button id="button-guardar-head-coachs" className="button__primary me-2 my-4" onClick={reset}><IoMdRefresh></IoMdRefresh></button> */}
                <button id="button-guardar-head-coachs" className="button__secondary my-4" onClick={guardarCoachs}>Guardar</button>
            </div>
        </div>
    )
}