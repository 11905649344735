import { InstagramPost } from "../interfaces/instagramPost";
import instagram001 from '../media/img/instagram/instagram_01.png';
import instagram002 from '../media/img/instagram/instagram_02.png';
import instagram003 from '../media/img/instagram/instagram_03.png';

export const MockInstagramPosts: InstagramPost[] = [
   {
      media_url: instagram001,
      //permalink: "https://www.instagram.com/p/C0KiUUUi5Lv/",
      id: "17972630807182340"
   },
   {
      media_url: instagram002,
      //permalink: "https://www.instagram.com/p/C0H7jXFvQl_/",
      id: "17975564542981734"
   },
   {
      media_url: instagram003,
      //permalink: "https://www.instagram.com/p/Cz4PoTrss6q/",
      id: "17966373002110229"
   },
]