import { useEffect, useState } from "react"
import "./style.css"
import { Documento } from "../../interfaces/documento"
import { MockDocumentos } from "../../mocks/documentos";
import CardDocumento from "../../components/cardDocumento";
import { GetAllDocumentos } from "../../services/documentoService";

export default function Documentos() {
    const [documentos, setDocumentos] = useState<any>();
    const [documentosRugby, setDocumentosRugby] = useState<any>();
    const [documentosHockey, setDocumentosHockey] = useState<any>();

    useEffect(() => {
        fetchDocumentos();
    }, []);

    const fetchDocumentos = async () => {
        let documentosResponse = await GetAllDocumentos();

        const documentosAgrupados = groupBy(documentosResponse, (e: any) => e?.area?.descripcion);
        setDocumentos(documentosAgrupados);

        if(documentosAgrupados?.Rugby){
            const documentosAgrupadosRugby = groupBy(documentosAgrupados?.Rugby, (e: any) => e?.categoria?.descripcion.replace(" ", "_"));
            setDocumentosRugby(documentosAgrupadosRugby);
        }
        
        if(documentosAgrupados?.Hockey){
            const documentosAgrupadosHockey = groupBy(documentosAgrupados?.Hockey, (e: any) => e?.categoria?.descripcion.replace(" ", "_"));
            setDocumentosHockey(documentosAgrupadosHockey);
        }
    };

    const groupBy = (x: any, f: any) => x.reduce((a: any, b: any, i: any) => ((a[f(b, i, x)] ||= []).push(b), a), {});

    const renderDocumentos = (docs: any[]) => docs.map((v: any, i: any) => <CardDocumento key={i} documento={v}></CardDocumento>);

    return (
        <section className="section__documentos d-flex justify-content-center flex-column gap-3 my-3 px-3">
            <h3 className="fw-bold mt-3 text-center">Documentación importante</h3>
            <div className="d-flex justify-content-center">
                {documentos?.Club ? <article className="d-flex justify-content-center flex-column">
                    <h4 className="title fw-bold mx-auto my-3 text-start text-md-center">Club</h4>
                    <div className="d-flex justify-content-start align-items-start flex-column">
                        {documentos?.Club? renderDocumentos(documentos?.Club): <h6 className="text-secondary">No se encontraron documentos</h6>}
                    </div>
                </article> : ''}
            </div>

            <div className="row px-2 px-md-5">
                <article className="col-12 col-sm-6 d-flex justify-content-start mb-3">
                    <div className="column__documentos">
                        <h4 className="title fw-bold mx-auto my-3 text-start">Rugby</h4>

                        {documentosRugby?.Plantel_superior? <h6 className="text-secondary fw-bold mt-3">Plantel Superior</h6>:''}
                        {documentosRugby?.Plantel_superior? renderDocumentos(documentosRugby?.Plantel_superior):''}
                        
                        {documentosRugby?.Juveniles? <h6 className="text-secondary fw-bold mt-3">Juveniles</h6>:''}
                        {documentosRugby?.Juveniles? renderDocumentos(documentosRugby?.Juveniles):''}

                        {documentosRugby?.Infantiles? <h6 className="text-secondary fw-bold mt-3">Infantiles</h6>:''}
                        {documentosRugby?.Infantiles? renderDocumentos(documentosRugby?.Infantiles):''}
                        {documentosRugby? '': <h6 className="text-secondary">No se encontraron documentos</h6>}
                    </div>
                </article>

                <article className="col-12 col-sm-6 d-flex justify-content-end">
                    <div className="column__documentos">
                        <h4 className="title fw-bold mx-auto my-3 text-start">Hockey</h4>
                        
                        {documentosHockey?.Plantel_superior? <h6 className="text-secondary fw-bold mt-3">Plantel Superior</h6>:''}
                        {documentosHockey?.Plantel_superior? renderDocumentos(documentosHockey?.Plantel_superior):''}
                        
                        {documentosHockey?.Juveniles? <h6 className="text-secondary fw-bold mt-3">Juveniles</h6>:''}
                        {documentosHockey?.Juveniles? renderDocumentos(documentosHockey?.Juveniles):''}

                        {documentosHockey?.Infantiles? <h6 className="text-secondary fw-bold mt-3">Infantiles</h6>:''}
                        {documentosHockey?.Infantiles? renderDocumentos(documentosHockey?.Infantiles):''}
                        {documentosHockey? '': <h6 className="text-secondary">No se encontraron documentos</h6>}    
                    </div>
                </article>
            </div>
        </section>
    )
}