import "./style.css"
import FormAdministracion from "./admin-accordion"
import { useAuth } from "../../utils/authContext";
import { UnauthorizedLogout } from "../../utils/unauthorizedLogout";

export default function Administracion(){
    const auth = useAuth();

    async function logout(){
        auth.logout();
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }

    return(
        <section className="administracion__section pb-5">
            <h3 className="title p-3 fw-bold text-center my-4">Administración</h3>
            <FormAdministracion></FormAdministracion>
            <div className="d-flex justify-center align-center">
                <button className="button__primary mx-auto my-2" onClick={logout}>Salir</button>
            </div>
        </section>
        
    )
}