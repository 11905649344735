import "./style.css"
import { useEffect, useState } from "react";
import { Empleado } from "../../interfaces/empleado";
import { DeleteEmpleado, GetAllEmpleados, InsertEmpleado, UpdateEmpleado } from "../../services/empleadoService";
import { useForm } from "react-hook-form";
import { IoMdRefresh } from "react-icons/io";
import InputEmpleado from "../../components/inputEmpleado";
import InputEmpleadoMultiple from "../../components/inputEmpleadoMultiple";
import { confirmAlert } from 'react-confirm-alert';
import { ConfirmModal } from "../../utils/confirmModal";
import { useAuth } from "../../utils/authContext";

export default function AdminComision(){
    const [empleados, setEmpleados] = useState<Empleado[] | undefined>();
    const [vocalesTitulares, setVocalesTitulares] = useState<Empleado[] | undefined>();
    const [vocalesSuplentes, setVocalesSuplentes] = useState<Empleado[] | undefined>();
    const [revisores, setRevisores] = useState<Empleado[] | undefined>();
    const [token, setToken] = useState<string>('');
    const auth = useAuth();

    useEffect(() => {
        setToken(auth.token);
        fetchEmployees();
    }, [empleados])

    const fetchEmployees = async () => {
        let employees = empleados || await GetAllEmpleados();
        //console.log(employees)
        setEmpleados(employees);
        setVocalesTitulares(employees?.filter(x => x.tipoEmpleado?.id == 7));
        setVocalesSuplentes(employees?.filter(x => x.tipoEmpleado?.id == 8));
        setRevisores(employees?.filter(x => x.tipoEmpleado?.id == 9));
    }

    const { register, formState: { errors }, watch, handleSubmit, setValue } = useForm({
        values: {
            presidente: empleados?.find(x => x.tipoEmpleado?.id == 1)?.nombre || '',
            vicepresidente: empleados?.find(x => x.tipoEmpleado?.id == 2)?.nombre || '',
            secretario: empleados?.find(x => x.tipoEmpleado?.id == 3)?.nombre || '',
            tesorero: empleados?.find(x => x.tipoEmpleado?.id == 4)?.nombre || '',
            prosecretario: empleados?.find(x => x.tipoEmpleado?.id == 5)?.nombre || '',
            protesorero: empleados?.find(x => x.tipoEmpleado?.id == 6)?.nombre || '',
        }
    });

    function agregarVocalTitular() {
        setVocalesTitulares([...vocalesTitulares || [], { id: undefined, nombre: '', tipoEmpleado: {id: 7, descripcion: 'Vocales titulares'} }]);
    }

    function agregarVocalSuplente() {
        setVocalesSuplentes([...vocalesSuplentes || [], { id: undefined, nombre: '', tipoEmpleado: {id: 8, descripcion: 'Vocales suplentes'}  }]);
    }

    function agregarRevisor() {
        setRevisores([...revisores || [], { id: undefined, nombre: '', tipoEmpleado: {id: 9, descripcion: 'Revisores de cuentas'}  }]);
    }

    function eliminarEmpleado(id: any) {        
        let empleadoEliminado = empleados?.find(e => e.id == id);
        confirmAlert({
            title: "Administración",
            message: `¿Desea eliminar a ${empleadoEliminado?.nombre}?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                    },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: async () => 
                    {
                        let response = await DeleteEmpleado(id, token);
                        ConfirmModal("Confirmación", "Se eliminó el integrante correctamente.")
                        setEmpleados(empleados?.filter(e => e.id != id))
                    }
                }
            ]
        });
    }
    
    async function actualizarEmpleado(data: any) {
        data.idTipoEmpleado = data.tipoEmpleado?.id;
        if(data.id > 0){
            let result = await UpdateEmpleado(data, token);
            let empleadosActualizados = empleados?.map(x => x.id === data.id ? {...x , nombre: data.nombre}: x);
            localStorage.setItem("empleados", JSON.stringify(empleadosActualizados));
            setEmpleados(empleados?.map(x => x.id === data.id ? {...x , nombre: data.nombre}: x));  
        }
        else{
            if(data?.nombre != ""){
                let result = await InsertEmpleado(data, token);
                data.id = result?.id;
                //data.id = Date.now();
                //localStorage.setItem("empleados", JSON.stringify([...empleados!, data]));
                setEmpleados([...empleados!, data]);
            }
        }
    }

    function guardarEmpleados(){
        try {
            let buttonsGuardar = document.querySelectorAll(".button__guardar__comision");
            buttonsGuardar.forEach(element => {
                setTimeout(() => {
                    (element as HTMLButtonElement).click();                
                }, 1000);
            });
            localStorage.setItem("empleados", JSON.stringify(empleados));
            
            ConfirmModal("Confirmación", "Se actualizaron los integrantes de la comisión correctamente.")
        } catch (error) {
            ConfirmModal("Error", "Se produjo un error al actualizar los integrantes de la comisión.")
        }
    }

    function reset(){
        confirmAlert({
            title: "Administración",
            message: `¿Desea restablecer los valores por defecto?`,
            buttons: [
                {
                    label: 'Cancelar',
                    className: "button__primary"
                    },
                {
                    label: 'Confirmar',
                    className: "button__secondary",
                    onClick: () => 
                    {
                        localStorage.removeItem("empleados");
                        setTimeout(() => {
                            window.location.reload();          
                        }, 500);
                    }
                }
            ]
        });
    }

    const renderEmpleadosPrincipales = () => empleados?.filter(x => x.tipoEmpleado?.id! < 7).map((v: any, i: any) => 
    <InputEmpleado key={v.id || i} data={v} eliminarEmpleadoChild={eliminarEmpleado} actualizarEmpleadoChild={actualizarEmpleado}></InputEmpleado>);
    
    return (
        <div className="comision__container accordion-body p-0">

            {empleados? renderEmpleadosPrincipales(): ''}

            <br />
            <div className="container my-3">
                <div className="d-flex align-items-center justify-content-between">
                    <label className="text-left">Vocales Titulares</label>
                    <button className="button__secondary my-2" onClick={agregarVocalTitular}>+ Agregar</button>
                </div>

                {vocalesTitulares ? vocalesTitulares.map((v: any, i: any) => <InputEmpleadoMultiple key={v.id || i} data={v} eliminarEmpleadoChild={eliminarEmpleado} actualizarEmpleadoChild={actualizarEmpleado}></InputEmpleadoMultiple>) : ''}

            </div>
            <div className="container my-3">
                <div className="d-flex align-items-center justify-content-between">
                    <label className="text-left">Vocales Suplentes</label>
                    <button className="button__secondary my-2" onClick={agregarVocalSuplente}>+ Agregar</button>
                </div>

                {vocalesSuplentes ? vocalesSuplentes.map((v: any, i: any) => <InputEmpleadoMultiple key={v.id || i} data={v} eliminarEmpleadoChild={eliminarEmpleado} actualizarEmpleadoChild={actualizarEmpleado}></InputEmpleadoMultiple>) : ''}

            </div>
            <div className="container my-3">
                <div className="d-flex align-items-center justify-content-between">
                    <label className="text-left">Revisores de Cuentas</label>
                    <button className="button__secondary my-2" onClick={agregarRevisor}>+ Agregar</button>
                </div>

                {revisores ? revisores.map((v: any, i: any) => <InputEmpleadoMultiple key={v.id || i} data={v} eliminarEmpleadoChild={eliminarEmpleado} actualizarEmpleadoChild={actualizarEmpleado}></InputEmpleadoMultiple>) : ''}

            </div>
            <div className="container text-light w-100 d-flex align-items-center justify-content-center">
                {/* <button type="submit" id="button-guardar-head-coachs" className="button__primary me-2 my-4" onClick={reset}><IoMdRefresh></IoMdRefresh></button> */}
                <button type="submit" id="btnLoginSubmit" className="button__secondary my-4" onClick={guardarEmpleados}>Guardar</button>
            </div>
        </div>
    )
}