import './style.css'
import Header from '../../components/header'
import TopBanner from '../../components/top-banner'
import ClubComision from './comision'
import ClubHistoriaOrigenes from './historia-origenes'
import ClubHistoriaAscenso from './historia-ascenso'
import ClubHistoriaInstitucional from './historia-institucional'
import ClubHistoriaSiglo from './historia-siglo'
import ClubExPresidentes from './ex-presidentes'
import ClubSocios from './socios'
import Footer from '../../components/footer'

export default function ClubPage(){

    window.scrollTo(0, 0);
    
    return(
        <div className="div__home min-vh-100 h-10 club__container">
            <Header></Header>
            <TopBanner id={2}></TopBanner>
            <ClubHistoriaOrigenes></ClubHistoriaOrigenes>
            <ClubHistoriaAscenso></ClubHistoriaAscenso>
            <ClubHistoriaInstitucional></ClubHistoriaInstitucional>     
            <ClubHistoriaSiglo></ClubHistoriaSiglo>
            <ClubExPresidentes></ClubExPresidentes>
            <ClubComision></ClubComision>
            <ClubSocios></ClubSocios>
            <Footer></Footer>
        </div>
    )
}