import { HeadCoach } from "../interfaces/headCoach";

export const MockHeadCoachs: HeadCoach[] = 
[    
    {
        id: 1,
        nombre: 'Pablo Caffaro',
        telefono: '',
        correo: 'pablocaffaro@hotmail.com',
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 1,
            descripcion: 'Plantel superior'
        },
        idArea: 2,
        idCategoria: 1
    },
    {
        id: 2,
        nombre: 'Santiago Ferrara',
        telefono: '',
        correo: 'ferrarasantiago@gmail.com',
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 2,
            descripcion: 'Juveniles'
        },
        idArea: 2,
        idCategoria: 2
    },
    {
        id: 3,
        nombre: 'Pedro Fantini',
        telefono: '',
        correo: 'pedrofantini51@gmail.com',
        area: {
            id: 2,
            descripcion: 'Rugby'
        },
        categoria: {
            id: 3,
            descripcion: 'Infantiles'
        },
        idArea: 2,
        idCategoria: 3
    },
    {
        id: 4,
        nombre: 'Claudia Cantu',
        telefono: '221-6168165',
        correo: '',
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 1,
            descripcion: 'Plantel superior'
        },
        idArea: 3,
        idCategoria: 1
    },
    {
        id: 5,
        nombre: 'Claudia Cantu',
        telefono: '221-6168165',
        correo: '',
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 2,
            descripcion: 'Juveniles'
        },
        idArea: 3,
        idCategoria: 2
    },
    {
        id: 6,
        nombre: 'Claudia Cantu',
        telefono: '221-6168165',
        correo: '',
        area: {
            id: 3,
            descripcion: 'Hockey'
        },
        categoria: {
            id: 3,
            descripcion: 'Infantiles'
        },
        idArea: 3,
        idCategoria: 3
    }
]