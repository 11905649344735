import { useAuth } from "../authContext";

export const UnauthorizedLogout = async () => {
    
    const logOut = () => {
        localStorage.removeItem("token");
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    logOut();
}