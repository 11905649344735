import { enviroment } from "../interfaces/enviroment";
import { Empleado } from "../interfaces/empleado";
import { UnauthorizedLogout } from "../utils/unauthorizedLogout";
import { MockEmpleados } from "../mocks/empleados";

const BASE_URL: string = enviroment.urlBase() + "api/Empleado";
const ENV_DEMO: boolean = enviroment.demo();
let empleados = localStorage.getItem("empleados") ? JSON.parse(localStorage.getItem("empleados")!) : undefined;

export const GetAllEmpleados = async (): Promise<Empleado[]> => {
    if (ENV_DEMO){
        if (empleados === undefined){
            empleados = MockEmpleados;
            localStorage.setItem("empleados", JSON.stringify(empleados));
        }
        
        return empleados;
    }
    const url = `${BASE_URL}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: Empleado[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const GetAllEmpleadosByType = async (seccionId?: number): Promise<Empleado[]> => {
    if (ENV_DEMO){
        return empleados;
    }
    const url = `${BASE_URL}/${seccionId}`;
    try {
        const response = await fetch(url);
        if (!response.ok){
            throw new Error(response.statusText);
        }
        const data: Empleado[] = await response.json();
        return data;        
    } catch (error) {
        return [];
    }
}

export const InsertEmpleado= async (data: any, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }

    const url = `${BASE_URL}`;
    let result;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        result = await response.json();
        if(response.ok && response.status == 201){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const UpdateEmpleado = async (data: any, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        return 0;
    }

    const url = `${BASE_URL}`;    
    let result;
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(data) 
        })
        
        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);
        }
        result = await response.json();
        if(response.ok && response.status == 200){
            return result;
        }        
    } catch (error) {
        return undefined;
    }
}

export const DeleteEmpleado = async (id: number, JwtToken: string): Promise<any> => {
    if (ENV_DEMO){
        empleados = empleados.filter((x: any) => x.id != id);
        localStorage.setItem("empleados", JSON.stringify(empleados));        
        return "Empleado eliminado exitosamente";
    }
    
    const url = `${BASE_URL}/${id}`;
    let result;
    try {
        const response = await fetch(url, {
            method: "DELETE",
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JwtToken}`
            },
            body: JSON.stringify(id) 
        })

        if(response.status == 401){
            UnauthorizedLogout();
        }
        
        if (!response.ok){
            throw new Error(response.statusText);        
        }   
        
        if(response.ok){
            return response;
        }     
    } catch (error) {
        return undefined;
    }    
}