import SectionText from "../../components/sectionText";
import { Imagen } from "../../interfaces/imagen";
import "./style.css";

export default function ClubHistoriaSiglo(){

    const historia: Imagen = {
        id: 1,
        titulo: ``,
        subtitulo: '',
        descripcion: `Desde el comienzo de los años 2000 fuimos protagonistas de varios torneos organizados por la URBA y la UAR.

En el 2000 fuimos semifinalistas del torneo de la URBA, logrando así el 3° puesto. Ese mismo año fuimos campeones del torneo oficial reducido organizado por la URBA.
En los años 2011 y 2016 fuimos semifinalistas del Top 14 de la URBA.
En los años 2005 y 2017 fuimos finalistas del Torneo Nacional de Clubes.
Desde entonces logramos salir campeones de los torneos de la URBA: M- 20 (año 2004), M - 19 (año 2010), M-19 (año 2011), División Intermedia PS (año 2013), M - 15 (año 2019) y M - 17 (año 2021).

Con el objetivo inicial de integrar la familia al Club, en el año 2007 se incorporó el hockey femenino como deporte.
En los comienzos jugamos en el Campo Deportes del Colegio San Luis hasta que en el año 2015 se inauguró la cancha de cesped sintético, logrando el ascenso a la segunda división de la AHBA en el año 2023.
En el año 2024 terminamos de pagar a los dueños de los lotes del denominado “predio 2” que cuenta con 6 hectáreas delimitado por las calles 520 a 522 de 25 a 27.

Gracias al aporte de nuestros socios y socias hoy tenemos 12 hectáreas propias que incluyen 7 canchas de rugby, 5 de rugby infantil, 1 de hockey de césped sintético, 1 gimnasio, 2 utilerías, 7 vestuarios, una pileta, la Casona, el Quincho, el Quincho del anexo y la Posta del Ex.

En la Asamblea del año 2023 se aprobó que el predio del Club lleve el nombre de Gerardo Pedro Villadeamigo.

En el año 2024 en el Club somos más de 1700 socios y socias, de los cuales 750 son jugadores de rugby y 420 de hockey.
`,
        imagenUrl: "",
        tipoImagen: {
            id: 3,
            descripcion: 'Seccion'
        }
    }

    return (
        <section id="club-historia-ascenso" className="container-fluid pt-5 px-1 px-md-3 h-100 historia__ascenso__container">
            <h5 className="text-start px-3 px-md-5 fw-bold">El siglo XXI</h5>
            <SectionText info={historia}></SectionText>
        </section>
    )
}