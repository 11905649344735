
import { confirmAlert } from 'react-confirm-alert';

export const ConfirmModal = async (title: string, message: string) => {
    
    confirmAlert({
        title: title || "Confirmación",
        message: message,
        buttons: [
            {
                label: 'Cerrar',
                className: "button__secondary"
            }
        ]
    });
}