import { Imagen } from "../interfaces/imagen";
import bannerHome from "../media/img/banner/banner_home.webp";
import bannerClub from "../media/img/banner/banner_club.webp";
import bannerRugby from "../media/img/banner/banner_rugby.webp";
import bannerHockey from "../media/img/banner/banner_hockey.webp";

export const MockBanners: Imagen[] =
    [
        
        {
            id: 1,
            titulo: 'CLUB SAN LUIS',
            subtitulo: '',
            descripcion: '',
            imagenUrl: bannerHome,
            tipoImagen: {
                id: 1,
                descripcion: 'Banner'
            },
            idTipoImagen: 1
        },
        {
            id: 2,
            titulo: 'CLUB',
            subtitulo: '',
            descripcion: '',
            imagenUrl: bannerClub,
            tipoImagen: {
                id: 1,
                descripcion: 'Banner'
            },
            idTipoImagen: 1
        },
        {
            id: 3,
            titulo: 'RUGBY',
            subtitulo: '',
            descripcion: '',
            imagenUrl: bannerRugby,
            tipoImagen: {
                id: 1,
                descripcion: 'Banner'
            },
            idTipoImagen: 1
        },
        {
            id: 4,
            titulo: 'HOCKEY',
            subtitulo: '',
            descripcion: '',
            imagenUrl: bannerHockey,
            tipoImagen: {
                id: 1,
                descripcion: 'Banner'
            },
            idTipoImagen: 1
        }
    ]