import "./style.css";
import { useEffect, useState } from "react";
import { Imagen } from "../../interfaces/imagen";

interface CardPartnerProps{
    data?: Imagen;
}

export default function CardPartner({data}: CardPartnerProps){
    const [partner, setPartner] = useState(data);

    useEffect(() => {
        setPartner(data);
    }, []);

    return (
        <div className="item firstImage d-flex justify-content-center align-items-center">
            <div className="logoWrap d-flex justify-content-center align-items-center">
                <img src={data?.url} alt="Logo" height={64}/>
            </div>
        </div>
    )
}