import './style.css';
import imgLogo from "../../media/logo.png";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { TiShoppingCart } from "react-icons/ti";

interface HeaderProps {
  opacity?: boolean;
}

export default function Header({ opacity }: HeaderProps) {
  const [styleTop, setStyleTop] = useState(85);
  const [logo, setLogo] = useState(imgLogo);
  const [bgColor, setBgColor] = useState(opacity ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.0)');
  const [headerLogo, setHeaderLogo] = useState(imgLogo);
  const location = useLocation();
  const { hash, pathname } = location;
  const [path, setPath] = useState<string>(pathname.replace("/", ""));

  const { search } = useLocation();

  useEffect(() => {
    document.title = 'Club San Luis';
    //ReactGA.initialize('G-MVTJ9F4F2L');
    let parametros = localStorage.getItem("parametros") ? JSON.parse(localStorage.getItem("parametros")!) : {};
    let logoHeader = parametros?.logoHeader || imgLogo;
    setLogo(logoHeader);

    //onHeaderItemClick(document.querySelectorAll(".header__link"))

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const onScroll = (e: Event) => {
    const window = e.currentTarget as Window
    const header = document.getElementById("navHeader");

    if (window.scrollY > 0) {
      setStyleTop(0);
      let colorBackground = path != "marketing" ? "var(--first-color)" : "rgba(0,0,0,0.3)";
      setBgColor(colorBackground);
    }
    else {
      setStyleTop(85);
      let colorBackground = opacity ? 'rgba(0,0,0,0.0)' : 'rgba(0,0,0,0.0)';
      setBgColor(colorBackground);
    }
  }

  const changeBgColor = () => {
    setBgColor('var(--first-color)');
  }

  const navigate = useNavigate();

  const redirect = () => {
    navigate(`#/inicio/`);
    document.getElementById('btnToggleNavbar')?.click();
  }

  const returnTop = () => {
    window.scrollTo(0, 0);
  }
  function onHeaderItemClick(elements: any) {
    elements.forEach((element: any) => {
      element.addEventListener('click', () => {
        //document.title = 'Drop - ' + element?.id;
        ReactGA.pageview(window.location.pathname + "/" + element?.id);
      });
    });
  }

  return (
    <nav className="navbar navbar-expand-xl navbar__bg" id="navHeader" style={{ backgroundColor: bgColor }}>
      <a href="#/inicio" className="header__logo navbar-toggler ms-1 border-0" onClick={returnTop}><img src={imgLogo} alt="Logo" width={44} height={44} /></a>
      <button id="btnToggleNavbar" className="navbar-toggler bg-light mx-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={changeBgColor}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav d-flex justify-content-between">
          <div className="d-flex justify-content-start gap-5 ps-2 w-25">
            <li className="nav-item active">
              <a className="header__link" href="#/inicio" onClick={returnTop}>Inicio</a>
            </li>
            <li className="nav-item">
              <a className="header__link" href="#/club" >Club</a>
            </li>
            <li className="nav-item">
              <a className="header__link" href="#/rugby">Rugby</a>
            </li>
            <li className="nav-item">
              <a className="header__link" href="#/hockey">Hockey</a>
            </li>
          </div>
          <div className="">
            <li className="d-none d-xl-flex w-25 justify-content-center align-items-center m-auto">
              <a href="#/inicio" className="navbar-brand"><img src={imgLogo} className="header__logo" alt="Logo" width={50} height={50} onClick={returnTop} /></a>
            </li>
          </div>
          <div className="d-flex justify-content-end gap-3 pe-2 w-25">
            <li className="nav-item">
              <a className="header__link" href="#/documentos">Documentos</a>
            </li>
            <li className="nav-item">
              <a className="header__link" href="#/contacto">Contacto</a>
            </li>
            <li className="nav-item">
              <a className="header__link" href="https://portal.ourclub.io/sanluis/" target="_blank">Autogestión</a>
            </li>
            <li className="nav-item">
              <a className="header__link" href="https://clubsanluis.mitiendanube.com/" target="_blank">Shop</a>
            </li>
          </div>
          {/* <li className="nav-item">
            <a className="header__link" href="#/contacto">Autogestión</a>
          </li> */}
        </ul>
      </div>
    </nav>
  )
}